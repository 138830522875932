<template>
  <div class="card" style="width: 480px">
    <div class="card-image">
      <template v-if="!loading">
        <b-carousel :autoplay="false" :indicator="false">
          <b-carousel-item v-for="(img, i) in week.imgs" :key="i">
            <img :src="img" alt="Resort Image" />
          </b-carousel-item>
        </b-carousel>
      </template>
      <b-skeleton width="480px" height="384px" :active="loading"></b-skeleton>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <template v-if="!loading">
            <p class="title is-4">{{ week.ResortName }}</p>
            <p class="subtitle is-6">
              {{ resort && resort.Address1
              }}{{
                resort &&
                resort.Address2 &&
                resort.Address2.length > 0 &&
                `, ${resort.Address2}`
              }}{{
                resort &&
                resort.Address3 &&
                resort.Address3.length > 0 &&
                `, ${resort.Address3}`
              }}{{
                resort &&
                resort.Address4 &&
                resort.Address4.length > 0 &&
                `, ${resort.Address4}`
              }}, {{ resort && resort.City }}, {{ resort && resort.State }},
              {{ resort && resort.Country }}, {{ resort && resort.Zip }}
            </p>
            <div class="columns">
              <div
                class="column is-6"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <b-taglist attached style="cursor: pointer">
                  <b-tag
                    @click="openUrlGoogleMap(week.GPS_Lat, week.GPS_Long)"
                    type="is-info is-light"
                    icon="map-marker"
                    icon-pack="fas"
                    >Google Map</b-tag
                  >
                  <b-tag
                    @click="openUrlGoogleMap(week.GPS_Lat, week.GPS_Long)"
                    type="is-link is-light"
                  >
                    <b-icon pack="fas" icon="external-link-alt" size="is-small">
                    </b-icon
                  ></b-tag>
                </b-taglist>
              </div>
              <div
                class="column is-6"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <b-taglist attached style="cursor: pointer">
                  <b-tag
                    @click="showAmenities(resort.amenities)"
                    type="is-link is-light"
                    icon="concierge-bell"
                    icon-pack="fas"
                    >{{ i18n.amenities[iso] }}</b-tag
                  >
                  <!-- <b-tag
                    @click="openUrlGoogleMap(week.GPS_Lat, week.GPS_Long)"
                    type="is-link is-light"
                  >
                    <b-icon pack="fas" icon="external-link-alt" size="is-small">
                    </b-icon
                  ></b-tag> -->
                </b-taglist>
              </div>
            </div>
          </template>
          <b-skeleton size="is-large" :active="loading" :count="4"></b-skeleton>
        </div>
      </div>

      <div class="content">
        <template v-if="!loading">
          <p v-show="resort"></p>
          {{
            resort && iso === "en" ? resort.HighlightsEN : resort.HighlightsSP
          }}
        </template>
        <b-skeleton size="is-large" :active="loading" :count="6"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import i18n from "../utils/i18n";

export default {
  name: "VacancyBackofficeModalViewResort",
  props: ["week"],
  data() {
    return {
      resort: null,
      loading: false,
      //i18n
      i18n,
      iso: null,
    };
  },

  mounted() {
    this.getResort();
  },

  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },

  methods: {
    getResort() {
      this.loading = true;
      axios({
        method: "get",
        url: `${API_URL}/resort/${this.week.ResortId}`,
      })
        .then(({ data }) => {
          console.log(data);
          this.resort = data.resort;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$buefy.toast.open({
            message: "Error al consultar datos",
            type: "is-danger",
          });
        });
    },
    openUrlGoogleMap(lat, lon) {
      // console.log(`https://www.google.com/maps/@${lat},${lon},17z?entry=ttu`);
      window.open(
        `https://www.google.com/maps/@${lat},${lon},18z?entry=ttu`,
        "_blank"
      );
    },
    showAmenities(amenities) {
      // let __html = `<!DOCTYPE html> <html> <body style="width: 600px;"> <div class="columns">`;

      // const numberTmp = 40;
      // const auxDiv = 3;

      // for (let i = numberTmp; i >= 1; i--) {
      //   if (i % 10 === 0 && i === numberTmp)
      //     __html += `<div class="column is-${auxDiv}">`;
      //   if (i % 10 === 0 && i < numberTmp && i > 1) {
      //     __html += `</div> <div class="column is-${auxDiv}">`;
      //   }
      //   if (amenities[numberTmp - i])
      //     __html += `<li>${amenities[numberTmp - i][this.iso]}</li>`;
      // }

      // __html += `</div> </body> </html>`;

      // console.log(__html);

      this.$buefy.dialog.alert({
        title: this.i18n.amenities[this.iso],
        message: amenities.map((a) => a[this.iso]).join(", "),
        confirmText: "OK",
        type: "is-success",
      });
    },
  },
};
</script>

<template>
  <div>
    <HeaderPage :title="title" icon="fa-tag" />
    <section class="my-container">
      <div class="my-buttons">
        <b-button
          icon-left="plus"
          icon-pack="fas"
          type="is-primary"
          @click="openModal()"
        >
          Crear código de descuento
        </b-button>
      </div>
      <div class="my-hr"></div>
      <TableList
        :data="discountCodes"
        :columns="columns"
        :loading="loadingDiscountCodes"
        :perPage="perPage"
        :paginate="paginate"
        :currentPage="currentPage"
      />
      <GenerateCouponModal
        :isActive.sync="isModalActive"
        @submit="createCouponCode"
        :code="code"
      />
    </section>
  </div>
</template>

<script>
import HeaderPage from "@/components/HeaderPage.vue";
import TableList from "@/components/TableList.vue";
import GenerateCouponModal from "./components/CreateModal.vue";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "IndexView",
  components: {
    HeaderPage,
    TableList,
    GenerateCouponModal,
  },
  data() {
    return {
      title: "Codigos de descuento",
      code: {},
      isModalActive: false,
      /* logica codigos de descuento */
      discountCodes: [],
      loadingDiscountCodes: false,
      columns: [
        { field: "code", label: "Código Dto.", type: "text" },
        { field: "type", label: "tipo", type: "text" },
        { field: "createdAtMS", label: "Fecha Alta", type: "date" },
        { field: "expiresAtMS", label: "Expiración", type: "date" },
        { field: "active", label: "Estado", type: "boolean" },
        { field: "discount", label: "Descuento", type: "discount" },
        { field: "maxDiscount", label: "Dto. Máximo", type: "amount" },
      ],
      filters: [],
      /* Pagination */
      paginate: {},
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.getDiscountCodes();
  },
  methods: {
    async getDiscountCodes() {
      this.loadingDiscountCodes = true;
      let query = `?limit=${this.perPage}&page=${this.currentPage}`;
      axios
        .get(`${API_URL}/discount-codes/list${query}`, this.filters)
        .then(({ data }) => {
          this.discountCodes = data?.codes || [];
          this.paginate = data?.paginate;
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: "Error al consultar códigos de descuento",
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loadingDiscountCodes = false;
        });
    },

    openModal() {
      this.isModalActive = true;
    },
    async createCouponCode() {
      axios
        .post(`${API_URL}/discount-codes/create`, { ...this.code })
        .then(() => {
          this.$buefy.toast.open({
            message: "Código de descuento creado",
            type: "is-success",
          });
          this.getDiscountCodes();
          this.isModalActive = false;
          this.code = {};
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: "Error al crear código de descuento",
            type: "is-danger",
          });
        });
    },
  },
};
</script>

<style scoped>
.my-buttons {
  padding: 0.5rem !important;
}
</style>

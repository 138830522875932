<template>
  <div class="column is-one-quarter">
    <div class="card card-statistic" :class="['color-' + color]">
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-size-1 has-text-white">
              <span v-if="format === 'USD'">$</span> {{ number }}
              <span class="small-text">{{ format }}</span>
            </p>
            <p class="subtitle has-text-white">{{ i18n[fieldIso][iso] }}</p>
          </div>
          <div class="icon-content">
            <i :class="['fas', icon, 'fa-2x']"></i>
          </div>
          <div class="tooltip-icon" :title="description" style="color: white">
            <i class="fas fa-info-circle fa-lg"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../utils/i18n";

export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    fieldIso: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      i18n,
      iso: null,
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
};
</script>

<style scoped>
.card-statistic {
  height: 200px;
}
.media-content {
  gap: 3.5rem;
}
.white-text {
  color: white;
}
.icon-content {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 50%;
}
.small-text {
  font-size: 2.5rem;
}
.tooltip-icon {
  position: absolute;
  bottom: 10px;
  right: 12px;
  cursor: pointer;
}

.tooltip-icon:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 2px;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  white-space: normal;
  z-index: 1000;
  width: 270px;
  overflow-wrap: break-word;
  text-align: center;
}

.color-sky-blue {
  background-color: #7ac1f9;
  color: #7ac1f9;
}
.color-ligth-blue-grey {
  background-color: #b0bec5;
  color: #b0bec5;
}
.color-liberty {
  background-color: #526cc1;
  color: #526cc1;
}
.color-periwinkle {
  background-color: #9297cf;
  color: #9297cf;
}
.color-golden-yellow {
  background-color: #feb66c;
  color: #feb66c;
}
.color-mustard-yellow {
  background-color: #e6ce54;
  color: #e6ce54;
}
.color-sage-green {
  background-color: #99d386;
  color: #99d386;
}
.color-charcoal-blue {
  background-color: #3f4f5c;
  color: #3f4f5c;
}
.color-fern-green {
  background-color: #547e3f;
  color: #547e3f;
}
</style>

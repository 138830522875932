<template>
  <div class="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
    <div
      class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"
    >
      <div class="flex flex-col overflow-y-auto md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img
            aria-hidden="true"
            class="object-cover w-full h-full dark:hidden"
            src="https://thesun.my/binrepository/female-tourists-spread-their-arms-held-their-wings_3518152_20231003092457.jpg"
            alt="Office"
          />
          <img
            aria-hidden="true"
            class="hidden object-cover w-full h-full dark:block"
            src="https://thesun.my/binrepository/female-tourists-spread-their-arms-held-their-wings_3518152_20231003092457.jpg"
            alt="Office"
          />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <!-- <h1
              class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200"
            >
              Login
            </h1> -->
            <img
              aria-hidden="true"
              class="object-cover w-full h-full dark:hidden"
              :src="logo"
              alt="vacancy"
            />
            <hr class="my-8" />
            <label class="block text-sm">
              <span class="text-gray-700 dark:text-gray-400">Email</span>
              <input
                v-model="email"
                class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                placeholder="user@example.com"
                type="email"
              />
            </label>
            <label class="block mt-4 text-sm">
              <span class="text-gray-700 dark:text-gray-400">Password</span>
              <input
                v-model="password"
                class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                placeholder="***************"
                type="password"
              />
            </label>

            <!-- You should use a button here, as the anchor is only used for the example  -->
            <b-button type="is-success" expanded @click="loginUserClub"
              ><b>LOGIN</b></b-button
            >
            <!-- <a
              @click="findUser"
              style="cursor: pointer"
              class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            >
              LOGIN
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import i18n from "../../utils/i18n";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: null,
      password: null,
      i18n,
      logo: "https://booking.vacancyrewards.com/css/clientes/vacancyrewards/images/brand-primary.png",
      users: [
        {
          user: "kovayadmin",
          email: "kovayadmin@mail.com",
          pass: "kovayadmin",
          name: "kovayadmin",
          role: "kovay",
          userId: -901,
          iso: "en",
        },
        {
          user: "kovayexit",
          email: "kovayexit@mail.com",
          pass: "kovayexit",
          name: "kovayexit",
          role: "kovay",
          userId: -902,
          iso: "en",
        },
      ],
    };
  },
  async created() {
    await this.insertIcon();
    await this.insertName();

    const currentUrl = window.location.href;
    if (/hgadmin/.test(currentUrl)) {
      this.logo =
        "https://tr2storage.blob.core.windows.net/agencylogos/KLJeZ76cjnX0Kni70W-VRJZ7GzYxHwFniR.png";
    }
  },
  methods: {
    insertIcon() {
      let link = null;
      link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href =
        "https://booking.vacancyrewards.com/css/clientes/vacancyrewards/images/favicon.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    insertName() {
      let text = document.createTextNode("Capturador");
      let title = document.createElement("title");
      title.appendChild(text);
      document.getElementsByTagName("head")[0].appendChild(title);
    },
    goPath(path) {
      this.$router.push(path);
    },
    findUser() {
      axios({
        method: "post",
        url: `${API_URL}/login`,
        data: {
          email: this.email,
          password: this.password,
        },
      })
        .then(({ data }) => {
          console.log(data);
          localStorage.setItem("user", data.user);
          localStorage.setItem("role", data.role);
          localStorage.setItem("userEmail", data.email);
          localStorage.setItem("userId", data.userId);
          // localStorage.setItem("permissions", data.permissions);
          // $cookies.set("user", data.user);

          if (!localStorage.getItem("iso")) localStorage.setItem("iso", "es");

          this.goPath("/week");
        })
        .catch((err) => {
          console.log(err);
          this.$buefy.toast.open({
            message: "Error, email o contraseña invalida",
            type: "is-danger",
          });
        });
    },
    logInUser() {
      const user = this.users.find((u) => u.email === this.email);
      if (!user) {
        this.$buefy.dialog.alert({
          title: this.i18n.errorLogin.en,
          message: this.i18n.userNotExists.en,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        return;
      }

      if (user.pass !== this.password) {
        this.$buefy.dialog.alert({
          title: this.i18n.errorLogin.en,
          message: this.i18n.wrongPassword.en,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        return;
      }

      localStorage.setItem("user", user.user);
      localStorage.setItem("role", user.role);
      localStorage.setItem("userEmail", user.email);
      localStorage.setItem("userId", user.userId);
      localStorage.setItem("iso", user.iso);

      this.goPath("/new-member");
    },
    loginUserClub() {
      axios({
        method: "post",
        url: `${API_URL}/users/login-club`,
        data: {
          email: this.email,
          password: this.password,
        },
      })
        .then(({ data }) => {
          console.log(data);
          localStorage.setItem("user", data.user);
          localStorage.setItem("role", data.role);
          localStorage.setItem("userEmail", data.email);
          localStorage.setItem("userId", data.userId);
          localStorage.setItem("clubes", JSON.stringify(data.clubes));
          localStorage.setItem("club", JSON.stringify(data.clubes[0]));
          localStorage.setItem("permissions", JSON.stringify(data.permissions));

          if (!localStorage.getItem("iso")) localStorage.setItem("iso", "en");

          this.goPath("/new-member");
        })
        .catch((err) => {
          console.log(err);
          this.$buefy.toast.open({
            message: "Error, email o contraseña invalida",
            type: "is-danger",
          });
        });
    },
  },
};
</script>

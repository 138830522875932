<template>
  <div class="reservation-resume">
    <h2>Resumen de la Reservación:</h2>
    <div class="details">
      <p><strong>LoginID:</strong> {{ reservation.LoginID }}</p>
      <p><strong>Nombre del Hotel:</strong> {{ reservation.NombreHotel }}</p>
      <p><strong>Check-in:</strong> {{ formatDate(reservation.Checkin) }}</p>
      <p><strong>Check-out:</strong> {{ formatDate(reservation.Checkout) }}</p>
      <p><strong>País:</strong> {{ reservation.Pais }}</p>
      <p><strong>Estado:</strong> {{ reservation.Estado }}</p>
      <p><strong>Dirección:</strong> {{ reservation.Direccion }}</p>
      <p><strong>Habitaciones:</strong> {{ reservation.Habitaciones }}</p>
      <p>
        <strong>Monto:</strong> {{ reservation.Monto }}
        {{ reservation.Moneda.toUpperCase() }}
      </p>
      <p>
        <strong>Notas Importantes:</strong> {{ reservation.NotasImportantes }}
      </p>
    </div>
    <div
      v-for="(habitacion, index) in reservation.HabitacionesData"
      :key="index"
      class="room-details"
    >
      <h3>Habitación {{ index + 1 }}:</h3>
      <p><strong>Titular:</strong> {{ habitacion.Titular }}</p>
      <p>
        <strong>Tipo de habitación:</strong> {{ habitacion.TipoHabitacion }}
      </p>
      <p><strong>Adultos:</strong> {{ habitacion.Adultos }}</p>
      <p><strong>Menores:</strong> {{ habitacion.Menores }}</p>
      <p>
        <strong>Edades de los Menores:</strong>
        {{ habitacion.EdadesMenores.join(", ") || "N/A" }}
      </p>
    </div>
    <div class="contact-details">
      <h2>Detalles de Contacto:</h2>
      <h3>Correos Electrónicos:</h3>
      <ul>
        <li v-for="(email, index) in reservation.Contacto.Emails" :key="index">
          {{ email }}
        </li>
      </ul>
      <h3>Números Telefónicos:</h3>
      <ul>
        <li
          v-for="(phone, index) in reservation.Contacto.Telefonos"
          :key="index"
        >
          {{ phone }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationResume",
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
  },
};
</script>

<style scoped>
.reservation-resume {
  font-family: Arial, sans-serif;
  margin: 20px;
}
.details p {
  margin: 5px 0;
}
.room-details {
  margin-top: 20px;
}
.contact-details {
  margin-top: 20px;
}
.contact-details h3 {
  margin-bottom: 10px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin-bottom: 5px;
}
h2 {
  font-size: 22px;
  font-weight: 600;
}
h3 {
  font-size: 18px;
  font-weight: 600;
}
</style>

<template>
  <div
    class="card"
    style="height: auto; padding: 20px; scroll-behavior: smooth"
  >
    <article class="media" v-show="comments.length === 0">
      <div class="media-content">
        <div class="content">{{ i18n.noComment[iso] }}</div>
      </div>
    </article>
    <div style="overflow-y: scroll; max-height: 300px; scroll-behavior: smooth">
      <article class="media" v-for="(c, i) in comments">
        <figure class="media-left">
          <p class="image is-48x48">
            <img
              :src="`img/${
                c.CreadoPor &&
                c.CreadoPor.Usuario &&
                c.CreadoPor.Usuario === 'na'
                  ? 'bot'
                  : 'profile'
              }.png`"
            />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <span
                ref="lastcomment"
                id="scr"
                v-if="comments.length - 1 === i"
              ></span>
              <strong>{{
                c.CreadoPor
                  ? `${c.CreadoPor.Nombre} ${c.CreadoPor.Apellido}`
                  : "system"
              }}</strong>
              <br />
              {{ c.Comentario }}
              <br />
              <b-tag type="is-info"
                ><small>{{ (c.Tema && c.Tema.nombre) || "n/a" }}</small></b-tag
              >
              <b-tag type="is-light" style="margin-left: 10px"
                ><small>{{ formatDate(c.Fecha) }}</small></b-tag
              >
            </p>
          </div>
        </div>
      </article>
    </div>

    <hr />

    <article class="media">
      <figure class="media-left">
        <p class="image is-48x48">
          <img src="img/profile.png" />
        </p>
      </figure>
      <div class="media-content">
        <div class="field">
          <p class="control">
            <b-input
              v-model="text"
              maxlength="1000"
              type="textarea"
              :placeholder="i18n.addComment[iso]"
            ></b-input>
          </p>
        </div>
        <div class="columns is-multiline">
          <!-- <div class="column is-2">
            <b-field label-position="on-border" :label="i18n.topic[iso]">
              <b-select
                :disabled="topics.length === 0"
                :placeholder="i18n.topic[iso]"
                v-model="topicSelected"
                expanded
              >
                <option :value="null">-</option>
                <option
                  v-for="(item, idx) in topics"
                  :value="item.id_coment_accion"
                  :key="idx"
                >
                  {{ item.nombre }}
                </option>
              </b-select>
            </b-field>
          </div> -->
          <div class="column is-2">
            <b-button
              icon-left="paper-plane"
              icon-pack="fas"
              type="is-success"
              expanded
              @click="createComment"
              >{{ i18n.send[iso] }}</b-button
            >
          </div>
          <div :class="`column is-8`"></div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";

export default {
  props: ["ContratoId"],
  data() {
    return {
      labelPosition: "on-border",
      isOpen: true,
      logs: [],
      loading: false,
      comments: [],
      text: null,
      user: null,
      topics: [],
      topicSelected: 99,
      show: false,

      //
      tabXml: "req",
      lastTabXml: "req",

      //i18n
      i18n,
      iso: null,
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
    this.$bus.on("refresh-comments-cap", async () => {
      await this.listComments();
    });

    this.user = localStorage.getItem("user");

    await this.listComments();
    await this.listTopics();
  },
  mounted() {},

  methods: {
    async listComments() {
      try {
        this.loading = true;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/comments/list`,
          {
            ContratoId: this.ContratoId,
          }
        );
        this.comments = data.comments;

        this.show = true;
        this.loading = false;
        this.goLastToComment();
      } catch (error) {
        this.loading = false;
        this.show = true;
        console.log(error);
        this.$buefy.toast.open({
          message: "Error al listar comentarios",
          type: "is-danger",
        });
      }
    },
    formatDate(date) {
      moment.locale(this.iso);
      return moment(date).calendar();
    },
    async createComment() {
      try {
        if (!this.topicSelected) {
          this.$buefy.toast.open({
            message: this.i18n.validateMsgTopic[this.iso],
            type: "is-warning",
          });
          return;
        }

        this.loading = true;
        await axios.post(`${API_URL}/catcher/members/comments/create`, {
          Comentario: this.text,
          Tipo: "contract",
          ContratoId: this.ContratoId,
          CreadoPor: localStorage.getItem("user"),
          Tema: this.topicSelected,
        });

        this.text = null;
        // this.topicSelected = null;
        this.listComments();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.$buefy.toast.open({
          message: "Error al listar comentarios",
          type: "is-danger",
        });
      }
    },
    goLastToComment() {
      // console.log(this.$refs.lastcomment)
      // console.log('lastComment.length > 0', lastComment.length > 0)
      // const lastComment = this.$refs.lastcomment;
      // if (lastComment && lastComment.length > 0)
      // this.$refs.lastcomment[0].scrollIntoView({ behavior: "smooth" });
      // const scr = document.getElementById("scr");
      // console.log("scr", scr);
      // if (scr) scr.scrollIntoView();
      // console.log('this.$refs', this.$refs)
      // console.log('this.$refs', this.$refs.lastcomment[0])
      // this.$refs.lastcomment[0].scrollIntoView({ behavior: "smooth" });
      // var container = this.$el.querySelector("#scr");
      // console.log('container', container)
      // container.scrollTop = container.scrollHeight;
    },
    async listTopics() {
      try {
        // this.loading = true;
        const { data } = await axios.get(`${API_URL}/catalogue/CatTopico`);
        this.topics = data;

        // this.loading = false;
      } catch (error) {
        // this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al listar tópicos",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <b-field label-position="on-border" :label="'Idioma'">
      <b-select expanded v-model="reservation.Idioma">
        <option :value="null"></option>
        <option :value="'es'">Español</option>
        <option :value="'en'">Ingles</option>
      </b-select>
    </b-field>
    <hr />
    <b-field label="Correo Electrónico" label-position="on-border">
      <b-input v-model="newEmail" type="email" expanded></b-input>
      <p class="control">
        <b-button class="button is-primary" @click="addEmail">Agregar</b-button>
      </p>
    </b-field>
    <table class="spaced">
      <thead>
        <tr>
          <th>Correo Electrónico</th>
          <th>Eliminar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(email, index) in reservation.Contacto.Emails" :key="index">
          <td>
            {{ email }}
          </td>
          <td>
            <b-button
              type="is-danger"
              icon-right="delete"
              size="is-small"
              @click="removeEmail(index)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <b-field label="Numero Telefónico con lada" label-position="on-border">
      <b-input v-model="newPhone" type="phone" expanded></b-input>
      <p class="control">
        <b-button class="button is-primary" @click="addPhone">Agregar</b-button>
      </p>
    </b-field>
    <table>
      <thead>
        <tr>
          <th>Telefono</th>
          <th>Eliminar</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(phone, index) in reservation.Contacto.Telefonos"
          :key="index"
        >
          <td>
            {{ phone }}
          </td>
          <td>
            <b-button
              type="is-danger"
              icon-right="delete"
              size="is-small"
              @click="removePhone(index)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableList from "@/components/TableList.vue";
export default {
  components: {
    TableList,
  },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newEmail: "",
      newPhone: "",
    };
  },
  methods: {
    addEmail() {
      if (
        this.newEmail &&
        !this.reservation.Contacto.Emails.includes(this.newEmail)
      ) {
        this.reservation.Contacto.Emails.push(this.newEmail);
        this.newEmail = "";
      }
    },
    removeEmail(index) {
      this.reservation.Contacto.Emails.splice(index, 1);
    },
    addPhone() {
      if (
        this.newPhone &&
        !this.reservation.Contacto.Telefonos.includes(this.newPhone)
      ) {
        this.reservation.Contacto.Telefonos.push(this.newPhone);
        this.newPhone = "";
      }
    },
    removePhone(index) {
      this.reservation.Contacto.Telefonos.splice(index, 1);
    },
  },
  created() {
    if (!this.reservation.Contacto) {
      this.$set(this.reservation, "Contacto", { Emails: [], Telefonos: [] });
    }
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 1rem;
}
div {
  margin-bottom: 1rem;
}
input {
  margin-right: 0.5rem;
}
button {
  margin-left: 0.5rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin-bottom: 0.5rem;
}
table {
  margin: 0 auto;
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
  border-radius: 3px;
  width: 100%;
}
th {
  padding: 0.4rem 1rem;
  text-align: left;
  border-bottom: 1px solid #dbdbdb;
  background: #f0f0f0;
}
th:last-child {
  width: fit-content;
}
tr:nth-child(even) {
  background: #f9f9f9;
}
td {
  padding: 0.2rem 1rem;
  border-bottom: 1px solid #dbdbdb;
}
td:last-child {
  padding: 0.2rem 0rem !important;
  text-align: center;
  width: 100px;
  border-left: 1px solid #dbdbdb;
}

.spaced {
  margin-bottom: 1.5rem !important;
}
</style>

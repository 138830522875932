<template>
  <section>
    <b-modal
      v-model="isActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Crear Reservación"
      close-button-aria-label="Cerrar"
      aria-modal
    >
      <template #default="{ close }">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Crear enlace de pago de reservación</p>
            <button
              type="button"
              class="delete"
              aria-label="close"
              @click="close"
            ></button>
          </header>
          <section class="modal-card-body">
            <ProgressSteps :steps="steps" :currentStep="step" class="spaced" />

            <ReservationForm v-if="step == 1" :reservation="reservation" />
            <ReservationTravelers
              v-if="step == 2"
              :LoginID="reservation.LoginID"
              :reservation="reservation"
            />
            <ReservationDescription
              v-if="step == 3"
              :reservation="reservation"
            />
            <ReservationContact v-if="step == 4" :reservation="reservation" />
            <ReservationResume v-if="step == 5" :reservation="reservation" />
          </section>
          <footer class="modal-card-foot">
            <b-button v-if="step != 1" label="Anterior" @click="prevStep" />
            <b-button
              v-if="step != 5"
              label="Siguiente"
              type="is-primary"
              @click="nextStep"
            />
            <b-button
              label="Guardar"
              v-if="step == 5"
              type="is-primary"
              @click="submitForm"
            />
          </footer>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ProgressSteps from "@/components/ProgressSteps.vue";
import ReservationForm from "./ReservationForm.vue";
import ReservationTravelers from "./ReservationTravelers.vue";
import ReservationDescription from "./ReservationDescription.vue";
import ReservationResume from "./ReservationResume.vue";
import ReservationContact from "./ReservationContact.vue";

export default {
  name: "GenerateReservationModal",
  components: {
    ProgressSteps,
    ReservationForm,
    ReservationTravelers,
    ReservationDescription,
    ReservationContact,
    ReservationResume,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localIsActive: this.isActive,
      formRef: null,
      step: 1,
      steps: [
        "Reservación",
        "Habitaciones",
        "Descripción",
        "Contacto",
        "Resumen",
      ],
      localReservation: {},
    };
  },
  watch: {
    isActive(newVal) {
      this.localIsActive = newVal;
    },
    localIsActive(newVal) {
      this.$emit("update:isActive", newVal);
    },
  },
  methods: {
    stepSubmit(data) {
      this.localReservation = { ...data, ...this.localReservation };
      console.log("submit");
    },
    handleSubmit() {
      /* this.localIsActive = false;
      this.$emit("submit", this.localReservation); */
      conosle.log("submit", this.localReservation);
    },
    nextStep() {
      if (this.step != 5) {
        this.step = this.step + 1;
      }
    },
    prevStep() {
      if (this.step != 1) {
        this.step = this.step - 1;
      }
    },
    submitForm() {
      this.$emit("submit", {});
    },
  },
};
</script>

<style scoped>
.spaced {
  margin-bottom: 3rem !important;
}
</style>

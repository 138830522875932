<template>
  <div v-if="!loadingPaymentLink">
    <section class="hero-title">
      <div class="title-body">
        <div class="title">
          <i class="fas fa-chevron-left" @click="goBack()"></i>
          <div class="tag-title">
            {{ iso == "en" ? `${tipo} ` : "" }}{{ i18n.paymentLink[iso] }}
            {{ iso == "es" ? `de ${tipo}` : "" }}
            <span :class="`${paymentLink.Estado} label-status`">{{
              paymentLink.Estado
            }}</span>
          </div>
        </div>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <hr />

    <h3 class="sub-title">Payment details</h3>

    <hr />
    <div>
      <table v-if="!loadingPaymentLink">
        <tr v-if="contract">
          <td>LoginID</td>
          <td>{{ contract.LoginID }}</td>
        </tr>

        <tr>
          <td>{{ i18n.amount[iso] }}</td>
          <td>{{ formatValue(paymentLink.Monto, "amount") }}</td>
        </tr>
        <tr>
          <td>{{ i18n.status[iso] }}</td>
          <td>
            <span :class="`${paymentLink.Estado} label-status`">{{
              paymentLink.Estado
            }}</span>
          </td>
        </tr>
        <tr>
          <td>Created by</td>
          <td>
            {{ paymentLink.CreadoPorEmail ? paymentLink.CreadoPorEmail : "-" }}
          </td>
        </tr>
        <tr>
          <td>{{ i18n.emitDate[iso] }}</td>
          <td>{{ formatValue(paymentLink.FechaCreacion, "date") }}</td>
        </tr>
        <tr>
          <td>{{ i18n.expiredAt[iso] }}</td>
          <td>{{ formatValue(paymentLink.FechaExpiracion, "date") }}</td>
        </tr>
        <tr v-if="paymentLink.Estado === 'paid'">
          <td>{{ i18n.cardHolder[iso] }}</td>
          <td>
            {{ session.payment_intent.payment_method.billing_details.name }}
          </td>
        </tr>
        <tr v-if="paymentLink.Estado === 'paid'">
          <td>{{ i18n.email[iso] }}</td>
          {{
            session.payment_intent.payment_method.billing_details.email
          }}
        </tr>
        <tr v-if="paymentLink.Estado === 'paid'">
          <td>Stripe Payment Intent ID</td>
          <td>{{ session.payment_intent.id }}</td>
        </tr>
        <tr v-if="paymentLink.Estado === 'paid'">
          <td>Stripe Charge ID</td>
          <td>{{ session.payment_intent.latest_charge }}</td>
        </tr>
        <tr v-if="paymentLink.Estado === 'paid'">
          <td>Metodo de pago</td>
          <td>STRIPE</td>
        </tr>
        <tr v-if="paymentLink.Estado === 'paid'">
          <td>Direccion IP</td>
          <td>{{ paymentLink.paymentIPAddress }}</td>
        </tr>
      </table>
    </div>
    <hr />
    <!--<h3 class="sub-title">Payment stripe</h3>

    <hr />
     <div class="stripe-section">
      <table>
        <tr>
          <td>ID</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ i18n.number[iso] }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ i18n.fingerprint[iso] }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ i18n.expiredAt[iso] }}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{ i18n.type[iso] }}</td>
          <td></td>
        </tr>
      </table>
      <table>
        <tr>
          <td>{{ i18n.address[iso] }}</td>
          <td>{{ i18n.country[iso] }}</td>
        </tr>
      </table>
    </div> -->
  </div>
</template>

<script>
import i18n, { paymentLink } from "@/utils/i18n";
import axios from "axios";
import moment from "moment";

const API_URL = process.env.VUE_APP_API_URL;
const SHORT_URL = process.env.VUE_APP_SHORT_URL;
export default {
  data() {
    return {
      paymentLinkId: null,
      paymentLink: null,
      loadingPaymentLink: true,
      // i18n
      i18n,
      iso: null,
      //loader
      tipo: null,
      session: null,
      contract: null,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
    this.paymentLinkId = this.$route.query.id;
  },
  async mounted() {
    await this.getPaymentLink();
  },
  methods: {
    goBack() {
      let path = `/payments-links`;
      this.$router.push(path);
    },
    async getPaymentLink() {
      try {
        this.loadingPaymentLink = true;
        const { data } = await axios.get(
          `${API_URL}/payments/retrieve/${this.paymentLinkId}`,
          {}
        );
        this.paymentLink = data.paymentLink || null;
        this.session = data.session || null;
        this.contract = data.contract || null;
        this.getType(data.paymentLink.Tipo);
        this.loadingPaymentLink = false;
      } catch (err) {
        this.loadingPaymentLink = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar enlaces de pago",
          type: "is-danger",
        });
      }
    },
    getType(type) {
      console.log("tipo", type);
      if (type === "individual-renewal" || type === "online-renewal") {
        this.tipo = this.i18n.renewal[this.iso];
      }

      if (type === "reservation") {
        this.tipo = this.i18n.reservation[this.iso];
      }
    },
    formatValue(value, type) {
      if (type === "amount" && value) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value);
      }
      if (type === "date") {
        return moment(value).format(
          this.iso === "en" ? "MM/DD/YYYY hh:mm:ss" : "DD/MM/YYYY hh:mm:ss"
        );
      }
      return value;
    },
  },
};
</script>

<style scoped>
.hero-title {
  margin-top: 4rem;
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
}

.sub-title {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
}

.title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.title i {
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.title i:hover {
  color: red;
}

table {
  font-size: 14px;
}

tr > td:first-child {
  padding-right: 7rem;
  font-weight: 500;
}

.stripe-section {
  display: flex;
}

.stripe-section table {
  flex-grow: 1;
}

.paid {
  background-color: #cffcb4;
  color: #417721;
  border: 1px solid rgba(65, 119, 33, 0.2);
}
.pending {
  background-color: #fef9c3;
  color: #ca8a04;
  border: 1px solid rgba(202, 139, 4, 0.2);
}

.open {
  background-color: #ffedd5;
  color: #ea580c;
  border: 1px solid rgba(234, 90, 12, 0.2);
}

.sent {
  background-color: #dbeafe;
  color: #2563eb;
  border: 1px solid rgba(37, 100, 235, 0.2);
}

.label-status {
  font-size: 12px;
  padding: 2px 0.5rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.FechaEnvio {
  background-color: #cffcb4;
  color: #417721;
  border: 1px solid rgba(65, 119, 33, 0.2);
  font-size: 12px;
  padding: 2px 0.5rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.tag-title {
  display: flex;
  align-items: end;
  gap: 1rem;
}
</style>

<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Datos del viajero</p>
      <!-- <button class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button> -->
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-6">
            <b-field label="Login ID" :label-position="labelPosition">
              <!-- <b-tooltip label="Tooltip right" position="is-right"> -->
              <b-autocomplete
                :placeholder="i18n.findLoginId[iso]"
                :keep-first="false"
                :open-on-focus="true"
                :clearable="true"
                icon="user"
                iconPack="fas"
                :data="members"
                field="ContratoId.LoginID"
                :loading="loadingMembers"
                :check-infinite-scroll="false"
                @typing="getAsyncData"
                @select="selectMember"
                @infinite-scroll="getMoreAsyncData"
                expanded
              >
                <template slot-scope="props">
                  <span
                    ><b>{{ props.option.ContratoId.LoginID }}</b> -
                    {{
                      `${props.option.Nombre} ${props.option.ApellidoPaterno}`
                    }}</span
                  >
                  <!-- <div class="media">
                    <div class="media-left">
                      <img
                        width="32"
                        :src="`https://image.tmdb.org/t/p/w500/${props.option.poster_path}`"
                      />
                    </div>
                    <div class="media-content">
                      {{ props.option.title }}
                      <br />
                      <small>
                        Released at {{ props.option.release_date }}, rated
                        <b>{{ props.option.vote_average }}</b>
                      </small>
                    </div>
                  </div> -->
                </template>
                <template #footer>
                  <span
                    v-show="pageMembers > totalPagesMembers"
                    class="has-text-grey"
                  >
                    {{ i18n.noMoreResults[iso] }}
                  </span>
                  <b-button
                    v-show="showMoreDataMembers"
                    expanded
                    size="is-small"
                    @click="getMoreAsyncData"
                    type="is-info"
                    outlined
                    :loading="loadingMembers"
                    >{{
                      (!loadingMembers && i18n.showMoreResults[iso]) || ""
                    }}</b-button
                  >
                </template>
              </b-autocomplete>
              <!-- </b-tooltip> -->
            </b-field>
          </div>
          <!-- <div class="column is-3">
            <b-field label="Login ID" :label-position="labelPosition">
              <b-input
                v-model="travelerInfo.loginId"
                @blur="mayus"
                maxlength="30"
              ></b-input>
            </b-field>
          </div> -->
          <div class="column is-3">
            <b-field
              :label="i18n.name[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.name ? 'is-danger' : ''"
              :message="!passInfo ? 'Este campo es requerido' : ''"
            >
              <b-input
                v-model="travelerInfo.name"
                @blur="mayus"
                maxlength="30"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              :label="i18n.lastname[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.lastname ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.lastname
                  ? 'Este campo es requerido'
                  : ''
              "
            >
              <b-input
                v-model="travelerInfo.lastname"
                @blur="mayus"
                maxlength="30"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field
              :label="i18n.cellphone[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.phone ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.phone
                  ? 'Este campo es requerido'
                  : ''
              "
            >
              <b-input
                v-model="travelerInfo.phone"
                @blur="mayus"
                maxlength="30"
                type="number"
              ></b-input>
            </b-field>
          </div>
          <!-- <div class="column is-3">
            <b-field
              :label="i18n.contract[iso]"
              :label-position="labelPosition"
            >
              <b-input
                v-model="travelerInfo.contract"
                @blur="mayus"
                maxlength="30"
              ></b-input>
            </b-field>
          </div> -->
          <div class="column is-4">
            <b-field
              :label="i18n.email[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.email ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.email
                  ? 'Este campo es requerido'
                  : ''
              "
            >
              <b-input
                v-model="travelerInfo.email"
                @blur="mayus"
                type="email"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field
              :label="i18n.email[iso] + ' 2'"
              :label-position="labelPosition"
            >
              <b-input
                v-model="travelerInfo.email2"
                @blur="mayus"
                type="email"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field
              :label="i18n.language[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.iso ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.iso ? 'Este campo es requerido' : ''
              "
            >
              <b-select expanded v-model="travelerInfo.iso">
                <option value="es">
                  {{ i18n.spanish[iso] }}
                </option>
                <option value="en">
                  {{ i18n.english[iso] }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              :label="i18n.country[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.country ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.country
                  ? 'Este campo es requerido'
                  : ''
              "
            >
              <b-select
                expanded
                v-model="travelerInfo.country"
                @input="listStates"
              >
                <option
                  v-for="(item, idx) in countries"
                  :value="item.Country"
                  :key="idx"
                >
                  {{ item.Country }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field
              :label="i18n.state[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.state ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.state
                  ? 'Este campo es requerido'
                  : ''
              "
            >
              <b-select
                expanded
                v-model="travelerInfo.state"
                :disabled="states.length === 0"
              >
                <option
                  v-for="(item, idx) in states"
                  :value="item.CODIGO"
                  :key="idx"
                >
                  {{ item.NOMBRE_ESTADO }}
                </option>
              </b-select>
            </b-field>
          </div>
          <!-- <div class="column is-2">
            <b-field
              :label="i18n.state[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.state ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.state
                  ? 'Este campo es requerido'
                  : ''
              "
            >
              <b-input v-model="travelerInfo.state" @blur="mayus"></b-input>
            </b-field>
          </div> -->
          <div class="column is-2">
            <b-field
              :label="i18n.city[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.city ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.city ? 'Este campo es requerido' : ''
              "
            >
              <b-input v-model="travelerInfo.city" @blur="mayus"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              :label="i18n.address[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.address ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.address
                  ? 'Este campo es requerido'
                  : ''
              "
            >
              <b-input v-model="travelerInfo.address" @blur="mayus"></b-input>
            </b-field>
          </div>

          <div class="column is-2">
            <b-field
              :label="i18n.zip[iso]"
              :label-position="labelPosition"
              :type="!passInfo && !travelerInfo.zipcode ? 'is-danger' : ''"
              :message="
                !passInfo && !travelerInfo.zipcode
                  ? 'Este campo es requerido'
                  : ''
              "
            >
              <b-input v-model="travelerInfo.zipcode" @blur="mayus"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a
        @click="$emit('close')"
        class="card-footer-item button is-danger is-outlined is-small"
        style="height: auto"
        ><b>CANCELAR</b></a
      >
      <a
        @click="mode === 'hold' ? holdFull() : addTraveler()"
        :class="`card-footer-item button is-success is-outlined is-small ${
          loading ? 'is-loading' : ''
        }`"
        style="height: auto"
        ><b>{{ mode === "hold" ? "HOLD" : "AGREGAR VIAJERO" }}</b></a
      >
      <!-- <a href="#" class="card-footer-item">Delete</a> -->
    </footer>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import i18n from "../utils/i18n";
import debounce from "lodash/debounce";

export default {
  name: "VacancyBackofficeModalHold",
  props: ["weeks", "inventoryId", "mode", "travel", "numberRef"],
  data() {
    return {
      labelPosition: "on-border",
      loading: false,
      loadingMembers: false,
      travelerInfo: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        address: null,
        city: null,
        state: null,
        country: null,
        zipcode: null,
        iso: "es",
        // contract: null,
        email2: null,
        loginId: null,
      },
      countries: [],
      states: [],
      passInfo: true,

      //i18n
      i18n,
      iso: null,

      //Async Members
      loginId: null,
      members: [],
      pageMembers: 1,
      totalPagesMembers: 0,
      showMoreDataMembers: true,
    };
  },
  created() {
    this.listCountries();
    if (this.mode === "add-travel" && this.travel) {
      this.travelerInfo = {
        name: this.travel.TravelerFirstName || this.travel.name,
        lastname: this.travel.TravelerLastName || this.travel.lastname,
        phone: this.travel.TravelerPhone || this.travel.phone,
        email: this.travel.TravelerEmail || this.travel.email,
        address: this.travel.TravelerAddress || this.travel.address,
        city: this.travel.TravelerCity || this.travel.city,
        state: this.travel.TravelerState || this.travel.state,
        country: this.travel.TravelerCountry || this.travel.country,
        zipcode: this.travel.TravelerZIPCode || this.travel.zipcode,
        iso: this.travel.iso || "es",
      };
    }

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },

  methods: {
    holdFull() {
      const week = this.weeks.find((w) => w.InventoryID === this.inventoryId);
      if (week && this.validateInfo()) {
        this.loading = true;
        // const { InvType, priceReal, ResortId: ResortID } = week;
        axios({
          method: "post",
          url: `${API_URL}/week/hold`,
          data: {
            client: {
              ...this.travelerInfo,
            },
            inventoryId: this.inventoryId,
            createdBy: localStorage.getItem("user"),
            createdByEmail: localStorage.getItem("userEmail"),
          },
        })
          .then(({ data }) => {
            console.log(data);
            this.loading = false;
            if (data && data.numberRef) {
              this.$buefy.toast.open({
                message: "Hold realizado con exito",
                type: "is-success",
              });
              // this.$emit("refresh");
              this.$emit("close");
            }
            if (data && !data.numberRef) {
              this.$buefy.toast.open({
                message: "Error al realizar el Hold, intente de nuevo.",
                type: "is-danger",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.$buefy.toast.open({
              message: "Error al reservar",
              type: "is-danger",
            });
          });
      }
    },
    listCountries() {
      axios({
        method: "get",
        url: `${API_URL}/week/countries-all`,
      })
        .then(({ data }) => {
          // console.log(data);
          this.countries = data.countries;
        })
        .catch((err) => {
          console.log(err);
          this.$buefy.toast.open({
            message: "Error al consultar datos",
            type: "is-danger",
          });
        });
    },
    listStates(country) {
      axios({
        method: "get",
        url: `${API_URL}/week/states/all?country=${country}`,
      })
        .then(({ data }) => {
          // console.log(data);
          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
          this.$buefy.toast.open({
            message: "Error al consultar datos",
            type: "is-danger",
          });
        });
    },
    addTraveler() {
      if (this.validateInfo()) {
        this.$buefy.toast.open({
          message: "Hold realizado con exito",
          type: "is-success",
        });
        this.$buefy.dialog.confirm({
          message: this.i18n.acceptUpdateTraveler[this.iso],
          confirmText: this.i18n.yes[this.iso],
          cancelText: this.i18n.cancel[this.iso],
          onConfirm: async () => {
            this.loading = true;
            axios({
              method: "post",
              url: `${API_URL}/week/add-traveler/hold/${this.numberRef}`,
              data: {
                ...this.travelerInfo,
              },
            })
              .then(({ data }) => {
                console.log(data);
                this.loading = false;
                this.$buefy.toast.open({
                  message: "Datos de viajero agregados con exito",
                  type: "is-success",
                });
                this.$emit("refresh");
                this.$emit("close");
              })
              .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$buefy.toast.open({
                  message: "Error al agregar datos del viajero",
                  type: "is-danger",
                });
              });
          },
        });
      }
    },
    validateInfo() {
      let pass = true;
      console.log("this.travelerInfo", this.travelerInfo);
      for (let i in this.travelerInfo) {
        if (!this.travelerInfo[i] && i !== "country" && i !== "state")
          pass = false;
      }
      pass = this.validateEmail();

      //Validate pais y estado
      const country = this.countries.find(
        (c) => c.Country === this.travelerInfo.country
      );
      if (!country) {
        this.travelerInfo.country = null;
        pass = false;
      } else {
        const state = this.states.find(
          (s) => s.CODIGO === this.travelerInfo.state
        );
        // if (!state) {
        //   this.travelerInfo.state = null;
        //   pass = false;
        // }

        if (
          (country.Country === "USA" ||
            country.Country === "Canada" ||
            country.Country === "Mexico" ||
            country.Country === "Australia") &&
          !state
        ) {
          this.travelerInfo.state = null;
          pass = false;
        }
      }

      this.passInfo = pass;
      return pass;
    },
    mayus() {
      for (let i in this.travelerInfo) {
        if (
          this.travelerInfo[i] &&
          i !== "country" &&
          i !== "state" &&
          i !== "iso"
        )
          this.travelerInfo[i] = `${this.travelerInfo[i]}`.toUpperCase();
      }

      // if (field === "name")
      //   this.travelerInfo.name = `${this.travelerInfo.name}`.toUpperCase();
    },
    validateEmail() {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.travelerInfo.email
        )
      )
        return true;
      else return false;
    },
    getAsyncData: debounce(async function (loginId) {
      if (this.loginId !== loginId) {
        this.loginId = loginId;
        this.members = [];
        this.pageMembers = 1;
        this.totalPagesMembers = 1;
      }

      if (!loginId.length) {
        this.members = [];
        this.pageMembers = 1;
        this.totalPagesMembers = 1;
        return;
      }
      if (this.pageMembers > this.totalPagesMembers) {
        return;
      }
      this.loadingMembers = true;

      const { data } = await axios.post(
        `${API_URL}/catcher/members/list-all/hold?limit=10&page=${this.pageMembers}`,
        { LoginID: loginId }
      );
      if (data.members.length === 0) this.showMoreDataMembers = false;
      else this.showMoreDataMembers = true;

      this.members = [
        // {
        //   Nombre: "n/a",
        //   ApellidoPaterno: "",
        //   ContratoId: { LoginID: loginId },
        // },
        ...this.members,
        ...data.members,
      ];
      this.pageMembers++;
      this.totalPagesMembers = data.paginate.totalPages;

      this.loadingMembers = false;
    }, 500),
    getMoreAsyncData: debounce(function () {
      this.getAsyncData(this.loginId);
    }, 250),
    getMoreAsyncDataNow() {
      this.getAsyncData(this.loginId);
    },
    selectMember(option) {
      console.log("option", option);
      if (option?.ContratoId?.LoginID) {
        this.travelerInfo.loginId = option?.ContratoId?.LoginID;
        this.travelerInfo.name = option?.Nombre;
        this.travelerInfo.lastname = option?.ApellidoPaterno;
        this.travelerInfo.phone = option?.Telefono;
        this.travelerInfo.email = option?.Email;
        this.travelerInfo.address = option?.Direccion;
        this.travelerInfo.city = option?.Ciudad;
        this.travelerInfo.zipcode = option?.CodigoPostal;

        if (option?.PaisId) {
          this.travelerInfo.country = option?.PaisId;
          this.listCountries();

          if (option?.EstadoId) {
            this.travelerInfo.state = option?.EstadoId;
            this.listStates(option?.PaisId);
          }
        }

        if (option?.ContratoId?.Language)
          this.travelerInfo.iso =
            option?.ContratoId?.Language.toString().toLowerCase();
      }
    },
  },
};
</script>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import routerCap from "./router/router.cap";
import "animate.css";
import VueCookies from "vue-cookies";
import VueBus from "vue-bus";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import Buefy from "buefy";
import "buefy/dist/buefy.css";
import {
  checkPermission,
  checkTotalPermissionsAvailable,
} from "./utils/permissions";

Vue.use(Buefy);
Vue.use(VueCookies);
Vue.use(VueBus);

Vue.component("ModalCard", () => import("./components/ModalCard.vue"));
Vue.component("multiselect", Multiselect);

Vue.config.productionTip = false;

Vue.prototype.$checkPermission = checkPermission;
Vue.prototype.$checkTotalPermissionsAvailable = checkTotalPermissionsAvailable;

new Vue({
  router: routerCap,
  render: (h) => h(App),
}).$mount("#app");

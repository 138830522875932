<template>
  <div class="card" :style="`width: 800px; height: auto`">
    <header class="card-header">
      <p class="card-header-title" style="font-size: large">
        {{ title }}
      </p>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="$emit('close')"
      >
        <span class="icon">
          <i class="fas fa-times" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns is-multiline"></div>
      </div>
    </div>
    <footer class="card-footer">
      <a
        @click="$emit('close')"
        class="card-footer-item button is-danger is-outlined is-small"
        :style="`height: auto`"
        ><b>{{ i18n.cancel[iso] }}</b></a
      >
      <a
        :class="`card-footer-item button is-success is-outlined is-small ${
          loading ? 'is-loading' : ''
        }`"
        :style="`height: auto`"
        @click="execAction"
        ><b>{{ textExec }}</b></a
      >
      <!-- <a href="#" class="card-footer-item">Delete</a> -->
    </footer>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../utils/i18n";

export default {
  props: {
    refresh: Function,
    exec: Function,
    textExec: String,
    title: String,
    width: Number,
    height: Number,

  },
  data() {
    return {
      loading: false,

      //i18n
      i18n,
      iso: null,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
  mounted() {},

  methods: {
    async execAction(){
        this.loading = true
        await this.exec()
        this.loading = false
    }
  },
};
</script>

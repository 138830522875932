<template>
  <div>
    <div class="spaced">
      <b-field label="Habitaciones" label-position="on-border" expanded>
        <b-input
          v-model.number="reservation.Habitaciones"
          type="number"
          required
          max="4"
          min="1"
        ></b-input>
      </b-field>
    </div>
    <div v-for="n in reservation.Habitaciones" :key="'habitacion-' + n">
      <h1 class="spaced red-rule">Habitacion {{ n }}</h1>
      <b-field
        :label="'Titular de la Habitación ' + n"
        label-position="on-border"
        expanded
        class="spaced"
      >
        <b-input
          v-model="reservation.HabitacionesData[n - 1].Titular"
          required
          placeholder="Nombre del Titular"
        ></b-input>
      </b-field>
      <b-field
        :label="'Tipo de Habitación ' + n"
        label-position="on-border"
        expanded
        class="spaced"
      >
        <b-input
          v-model="reservation.HabitacionesData[n - 1].TipoHabitacion"
          required
          placeholder="Tipo de Habitación"
        ></b-input>
      </b-field>
      <div class="flex spaced">
        <b-field
          :label="'Adultos en Habitación ' + n"
          label-position="on-border"
        >
          <b-input
            v-model.number="reservation.HabitacionesData[n - 1].Adultos"
            type="number"
            min="1"
            required
          ></b-input>
        </b-field>
        <b-field
          :label="'Menores en Habitación ' + n"
          label-position="on-border"
        >
          <b-input
            v-model.number="reservation.HabitacionesData[n - 1].Menores"
            type="number"
            min="0"
            required
          ></b-input>
        </b-field>
      </div>
      <div
        v-for="m in reservation.HabitacionesData[n - 1].Menores"
        :key="'menor-' + n + '-' + m"
      >
        <b-field
          :label="'Edad del Menor ' + m + ' en Habitación ' + n"
          label-position="on-border"
          expanded
          class="spaced"
        >
          <b-input
            v-model="reservation.HabitacionesData[n - 1].EdadesMenores[m - 1]"
            type="number"
            min="0"
            required
            placeholder="Edad del Menor"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationTravelers",
  props: {
    LoginID: {
      type: String,
    },
    reservation: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "reservation.Habitaciones": function (newVal) {
      this.updateRoomsArray(newVal);
    },
    "reservation.HabitacionesData": {
      handler(newVal) {
        newVal.forEach((room, index) => {
          this.updateArraySize(
            `HabitacionesData[${index}].EdadesMenores`,
            room.Menores
          );
        });
      },
      deep: true,
    },
  },
  methods: {
    updateRoomsArray(size) {
      if (!this.reservation.HabitacionesData) {
        this.$set(this.reservation, "HabitacionesData", []);
      }
      while (this.reservation.HabitacionesData.length < size) {
        this.reservation.HabitacionesData.push({
          Titular: "",
          Adultos: 1,
          Menores: 0,
          TipoHabitacion: "",
          EdadesMenores: [],
        });
      }
      while (this.reservation.HabitacionesData.length > size) {
        this.reservation.HabitacionesData.pop();
      }
    },
    updateArraySize(arrayPath, size) {
      const array = this.getNestedProperty(this.reservation, arrayPath);
      if (!array) {
        this.$set(this.reservation, arrayPath, []);
      }
      while (
        this.getNestedProperty(this.reservation, arrayPath).length < size
      ) {
        this.getNestedProperty(this.reservation, arrayPath).push("");
      }
      while (
        this.getNestedProperty(this.reservation, arrayPath).length > size
      ) {
        this.getNestedProperty(this.reservation, arrayPath).pop();
      }
    },
    getNestedProperty(obj, path) {
      return path.split(".").reduce((o, p) => (o ? o[p] : undefined), obj);
    },
    submitTravelers() {
      this.$emit("submitTravelers");
    },
  },
  mounted() {
    this.updateRoomsArray(this.reservation.Habitaciones);
  },
};
</script>

<style scoped>
.flex {
  width: 100%;
  gap: 0.5rem !important;
}

.spaced {
  margin-bottom: 1.5rem !important;
}
.flex > * {
  flex: 1 1 50%;
  box-sizing: border-box;
  width: 50%;
}

.red-rule {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #7755d1;
  position: relative;
  font-size: 24px;
  font-weight: 700;
}

.red-rule::before {
  content: "";
  width: 75px;
  height: 5px;
  position: absolute;
  background: #7755d1;
  bottom: -5px;
}
</style>

<template>
  <div>
    <div v-if="!custom">
      <b-tabs v-model="tabFilters" position="is-centered" @input="changeTab">
        <b-tab-item>
          <template #header>
            <b-icon icon="lightning-bolt" type="is-danger"></b-icon>
            <b>{{ i18n.fastSearch[iso] }}</b>
          </template>
          <div class="columns">
            <div class="column is-4">
              <b-field
                :type="
                  !passValidateSearch && searchMembers && searchMembers !== ''
                    ? 'is-danger'
                    : passValidateSearch &&
                      searchMembers &&
                      searchMembers !== ''
                    ? 'is-info'
                    : ''
                "
                :message="
                  !passValidateSearch && searchMembers && searchMembers !== ''
                    ? i18n.messageValidateSearch[iso]
                    : ''
                "
                grouped
              >
                <b-input
                  :placeholder="i18n.search[iso]"
                  v-model="searchMembers"
                  type="text"
                  icon="search"
                  icon-pack="fas"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                  @icon-right-click="clearInputSearch"
                  @input="validateSearch"
                >
                </b-input>
                <p class="control">
                  <b-button
                    class="button is-info"
                    @click="basicSearch(false)"
                    :disabled="
                      passValidateSearch &&
                      searchMembers &&
                      searchMembers !== ''
                        ? false
                        : true
                    "
                    >{{ i18n.search[iso] }}</b-button
                  >
                </p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-button
                icon-left="file"
                icon-pack="fas"
                type="is-info is-light"
                @click="basicSearch(true)"
                expanded
                outlined
                :loading="loadingExportMembers"
                >{{ i18n.exportToCsv[iso] }}</b-button
              >
            </div>
          </div>
        </b-tab-item>

        <b-tab-item>
          <template #header>
            <b-icon icon="filter" pack="fas" type="is-info"></b-icon>
            <b>{{ i18n.advancedSearch[iso] }}</b>
          </template>
          <div class="columns is-multiline">
            <div class="column is-3">
              <b-field label-position="on-border" label="LoginID">
                <b-input
                  v-model="advancedFilters.LoginID"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.contract[iso]">
                <b-input
                  v-model="advancedFilters.nroContract"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.name[iso]">
                <b-input
                  v-model="advancedFilters.Nombre"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.lastname[iso]">
                <b-input
                  v-model="advancedFilters.ApellidoPaterno"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.email[iso]">
                <b-input
                  v-model="advancedFilters.Email"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.agent[iso]">
                <b-select expanded v-model="advancedFilters.staff">
                  <option :value="null"></option>
                  <option
                    v-for="user in staff"
                    :value="user.user_gen"
                    :key="user._id"
                  >
                    {{ user.nombre }} {{ user.apellidopat }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.creationDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaCreacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.paidDate[iso]">
                <b-datepicker
                  v-model="advancedFilters.FechaPago"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                >
                </b-datepicker>
              </b-field>
            </div>
            <!-- <div class="column is-3">
            <b-field
              label-position="on-border"
              :label="i18n.registrationDate[iso]"
            >
              <b-datepicker
                v-model="advancedFilters.FechaRegistro"
                placeholder="DD/MM/YYYY"
                :min-date="minDate"
                :max-date="maxDate"
                :locale="iso"
                range
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              label-position="on-border"
              :label="i18n.processingDate[iso]"
            >
              <b-datepicker
                v-model="advancedFilters.FechaProcesable"
                placeholder="DD/MM/YYYY"
                :min-date="minDate"
                :max-date="maxDate"
                :locale="iso"
                range
              >
              </b-datepicker>
            </b-field>
          </div>
           -->
            <!-- <div class="column is-3">
            <b-field
              label-position="on-border"
              :label="i18n.cancellationDate[iso]"
            >
              <b-datepicker
                v-model="advancedFilters.FechaCancelacion"
                placeholder="DD/MM/YYYY"
                :min-date="minDate"
                :max-date="maxDate"
                :locale="iso"
                range
                :disabled="validateAdvancedFilters.FechaCancelacion"
              >
              </b-datepicker>
            </b-field>
          </div> -->
            <div class="column is-3 my-date">
              <b-field
                label-position="on-border"
                :label="i18n.renewalDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaRenovacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  :disabled="validateAdvancedFilters.FechaRenovacion"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="'Club'">
                <b-select v-model="advancedFilters.club" expanded>
                  <option :value="null"></option>
                  <option
                    v-for="club in clubesName"
                    :value="club._id"
                    :key="club._id"
                  >
                    {{ club.Nombre }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.subscriptionStatus[iso]"
              >
                <b-select expanded v-model="advancedFilters.status">
                  <option :value="null"></option>
                  <option :value="1">
                    {{ i18n.current[iso] }}
                  </option>
                  <option :value="0">
                    {{ i18n.expired[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <!-- <div class="column is-3">
            <b-field
              label-position="on-border"
              :label="i18n.membershipStatus[iso]"
            >
              <b-select expanded v-model="advancedFilters.membershipStatus">
                <option :value="null"></option>
                <option :value="true">
                  {{ i18n.active[iso] }}
                </option>
                <option :value="false">
                  {{ i18n.inactive[iso] }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field
              label-position="on-border"
              :label="i18n.contractStatus[iso]"
            >
              <b-select
                expanded
                v-model="advancedFilters.contractStatus"
                @input="checkValidateAdvancedFilters"
              >
                <option :value="null"></option>
                <option :value="1">
                  {{ i18n.pending[iso] }}
                </option>
                <option :value="2">
                  {{ i18n.procesable[iso] }}
                </option>
                <option :value="3">
                  {{ i18n.cancelled[iso] }}
                </option>
              </b-select>
            </b-field>
          </div> -->
            <!--  -->
            <!-- <div class="column is-2"></div> -->
            <!-- <div class="column is-2">
              <b-switch
                :rounded="false"
                size="is-medium"
                v-model="advancedFilters.onlyHeadlines"
                :true-value="true"
                :false-value="false"
                type="is-success"
              >
                {{ i18n.onlyHeadlines[iso] }}
              </b-switch>
            </div> -->
            <!--  <div class="column is-2">
            <b-button
              icon-left="search"
              icon-pack="fas"
              type="is-info"
              @click="advancedSearch(false)"
              expanded
              >{{ i18n.search[iso] }}</b-button
            >
          </div>
          <div class="column is-2">
            <b-button
              icon-left="redo"
              icon-pack="fas"
              type="is-info is-light"
              @click="clearAdvancedSearch"
              expanded
              >{{ i18n.resetSearch[iso] }}</b-button
            >
          </div>
          <div class="column is-2">
            <b-button
              icon-left="file"
              icon-pack="fas"
              type="is-info is-light"
              @click="advancedSearch(true)"
              expanded
              outlined
              :loading="loadingExportMembers"
              >{{ i18n.exportToCsv[iso] }}</b-button
            >
          </div> -->
            <div
              class="column is-3"
              v-if="filterFields.includes('EstadoPaymentLink')"
            >
              <b-field
                label-position="on-border"
                :label="i18n.paymentLinkStatus[iso]"
              >
                <multiselect
                  v-model="selectedEstadoPaymentLink"
                  :options="options"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder=""
                  label="name"
                  track-by="name"
                >
                </multiselect>
              </b-field>
            </div>
            <div class="column is-3" v-if="filterFields.includes('language')">
              <b-field
                label-position="on-border"
                :label="i18n.languageUser[iso]"
              >
                <b-select expanded v-model="advancedFilters.language">
                  <option :value="null"></option>
                  <option :value="'SP'">
                    {{ i18n.spanish[iso] }}
                  </option>
                  <option :value="'EN'">
                    {{ i18n.english[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-2">
              <b-button
                icon-left="search"
                icon-pack="fas"
                type="is-info"
                @click="advancedSearch(false)"
                expanded
                >{{ i18n.search[iso] }}</b-button
              >
            </div>
            <div class="column is-1">
              <b-button
                icon-left="eraser"
                icon-pack="fas"
                type="is-danger"
                @click="clearFilters(false)"
                expanded
              ></b-button>
            </div>
          </div>
          <div class="columns is-multiline"></div>
        </b-tab-item>
      </b-tabs>
    </div>
    <!-- FILTROS -->
    <div class="columns is-multiline space" v-if="custom">
      <div class="column is-3">
        <b-field label-position="on-border" label="LoginID">
          <b-input
            v-model="advancedFilters.LoginID"
            type="text"
            icon-right="times"
            icon-right-clickable
            expanded
          >
          </b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label-position="on-border" :label="i18n.contract[iso]">
          <b-input
            v-model="advancedFilters.nroContract"
            type="text"
            icon-right="times"
            icon-right-clickable
            expanded
          >
          </b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label-position="on-border" :label="i18n.name[iso]">
          <b-input
            v-model="advancedFilters.Nombre"
            type="text"
            icon-right="times"
            icon-right-clickable
            expanded
          >
          </b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label-position="on-border" :label="i18n.lastname[iso]">
          <b-input
            v-model="advancedFilters.ApellidoPaterno"
            type="text"
            icon-right="times"
            icon-right-clickable
            expanded
          >
          </b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label-position="on-border" :label="i18n.email[iso]">
          <b-input
            v-model="advancedFilters.Email"
            type="text"
            icon-right="times"
            icon-right-clickable
            expanded
          >
          </b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label-position="on-border" :label="'Club'">
          <b-select v-model="advancedFilters.club" expanded>
            <option :value="null"></option>
            <option
              v-for="club in clubesName"
              :value="club._id"
              :key="club._id"
            >
              {{ club.Nombre }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-3" v-if="role !== 'staff'">
        <b-field label-position="on-border" :label="i18n.agent[iso]">
          <b-select expanded v-model="advancedFilters.staff">
            <option :value="null"></option>
            <option v-for="user in staff" :value="user._id" :key="user._id">
              {{ user.Nombre }} {{ user.Apellido }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-3 my-date">
        <b-field label-position="on-border" :label="i18n.renewalDate[iso]">
          <b-datepicker
            v-model="advancedFilters.FechaRenovacion"
            placeholder="DD/MM/YYYY"
            :min-date="minDate"
            :max-date="maxDate"
            :locale="iso"
            range
            :disabled="validateAdvancedFilters.FechaRenovacion"
          >
          </b-datepicker>
        </b-field>
      </div>

      <div class="column is-2">
        <b-button
          icon-left="search"
          icon-pack="fas"
          type="is-info"
          @click="advancedSearch(false)"
          expanded
          >{{ i18n.search[iso] }}</b-button
        >
      </div>
      <div class="column is-1">
        <b-button
          icon-left="eraser"
          icon-pack="fas"
          type="is-danger"
          @click="clearFilters(false)"
          expanded
        ></b-button>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;
import i18n from "../utils/i18n";
import moment from "moment";
import Multiselect from "vue-multiselect";

const today = new Date();

export default {
  name: "FilterForm",
  props: {
    advancedFilters: Object,
    filterFields: Array,
    custom: Boolean,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      role: localStorage.getItem("role"),
      tabFilters: 1,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      loadingClubes: false,
      clubesName: [],
      options: [
        { name: "paid" },
        { name: "pending" },
        { name: "expired" },
        { name: "canceled" },
      ],
      staff: [],
      loadingExportMembers: false,
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },
      selectedEstadoPaymentLink: [],
      // i18n
      i18n,
      iso: localStorage.getItem("iso"),
      // DATES
      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),
      workMonth: moment().month(),
      workYear: moment().year(),
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
  async mounted() {
    await this.listAllClubes();
    await this.listAllStaff();
  },
  watch: {
    selectedEstadoPaymentLink: {
      handler(newVal) {
        this.advancedFilters.EstadoPaymentLink = newVal.map(
          (option) => option.name
        );
      },
      deep: true,
    },
  },
  methods: {
    validateSearch(text) {
      // return true;
      // this.passValidateSearch = /^[A-Za-z0-9]+$/gi.test(text);
      this.passValidateSearch = true;
    },
    clearInputSearch() {
      this.searchMembers = null;
      this.passValidateSearch = false;
      this.listAllMembers();
    },
    formatTextBySearch(text, field) {
      if (this.itemsFilters.includes(field)) {
        if (this.lastSearchMembers && text && text !== "") {
          const rgx = new RegExp(this.lastSearchMembers, "ig");
          const insertHtml = (match) => {
            return `<b style="color: #167df0;">${match}</b>`;
          };
          const __name = text.replace(rgx, insertHtml);

          return __name;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },

    openModalEditMember(member) {
      this.dataModalEditMember = member;
      this.isActiveModalEditMember = true;
    },
    restorePassword() {
      this.$buefy.dialog.confirm({
        title: this.i18n.resetPassword[this.iso],
        message: this.i18n.resetPassword__confirm[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-info is-light",
        onConfirm: () =>
          this.$buefy.toast.open(
            this.i18n.resetPassword__confirm__success[this.iso]
          ),
      });
    },
    openModalCommentMember(memberId) {
      this.memberIdModalCommentMember = memberId;
      this.isActiveModalCommentMember = true;
    },
    goPath(path) {
      this.$router.push(path);
    },
    changeTab() {
      localStorage.setItem("tabFilters", this.tabFilters);
    },
    async listAllClubes() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          select: "Nombre",
        });
        this.clubesName = data?.clubes || [];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    async listAllStaff() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(
          `${API_URL}/system/list-staff?assignment=true`,
          {
            staffModule: "renewals",
          }
        );
        this.staff = data?.users || [];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    advancedSearch(isExport = false) {
      /* if (isExport) {
        this.exportAllMembers(this.advancedFilters);
      } else {
        this.currentPage = 1;
        this.listAllMembers(this.advancedFilters);
      } */
      this.$emit("advancedSearch", {});
    },
    checkAdvancedSearch() {
      let result = false;
      for (let i in this.advancedFilters) {
        if (this.advancedFilters[i]) result = true;
      }
      return result;
    },
    onPageChange(page) {
      this.currentPage = page;
      if (this.searchMembers && this.searchMembers !== "") {
        this.listAllMembers({
          rgx: this.searchMembers,
        });
      } else if (this.checkAdvancedSearch(false)) {
        this.listAllMembers(this.advancedFilters);
      } else {
        this.listAllMembers();
      }
    },
    clearFilters() {
      this.selectedEstadoPaymentLink = [];
      this.$emit("clearFilters");
    },
    getMembersStatic(data) {
      let result = [];
      result.push({
        FullNombre: `${data.Nombre} ${data.ApellidoPaterno}`,
        Nombre: data.Nombre,
        ApellidoPaterno: data.ApellidoPaterno,
        Telefono: data.Telefono,
        Email: data.Email,
        Language: data.ContratoId?.Idioma,
        TipoSocioId: data.TipoSocio,
      });

      if (data?.coOwner)
        result.push({
          FullNombre: `${data.coOwner.Nombre} ${data.coOwner.ApellidoPaterno}`,
          Nombre: data.coOwner.Nombre,
          ApellidoPaterno: data.coOwner.ApellidoPaterno,
          Telefono: data.coOwner.Telefono,
          Email: data.coOwner.Email,
          Language: data.ContratoId?.Idioma,
          TipoSocioId: data.coOwner.TipoSocio,
        });

      return result;
    },
    formatDate(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).format("MMM/DD/YYYY").toUpperCase();
    },
    getBeneficiariesStatic(data) {
      let result = [];
      if (data?.beneficiaries)
        result = [
          data.beneficiaries
            .map(
              (b) =>
                `${this.formatTextBySearch(
                  b.Nombre,
                  "Nombre"
                )} ${this.formatTextBySearch(
                  b.ApellidoPaterno,
                  "ApelidoPaterno"
                )}`
            )
            .join(", "),
        ];

      return result.filter((b) => b && b !== "");
    },

    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
  },
};
</script>

<style>
.checkbox {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
}
</style>

<template>
  <div class="card" style="width: 600px; height: auto">
    <header class="card-header">
      <p class="card-header-title" style="font-size: large">
        {{ i18n.sendConfirmation[iso] }}
      </p>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="$emit('close')"
      >
        <span class="icon">
          <i class="fas fa-times" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="columns">
          <div class="column is-12">
            <b-field :type="onlyCc ? 'is-danger' : 'is-success'">
              <p class="control">
                <span class="button is-static">{{ i18n.client[iso] }}</span>
              </p>
              <b-input
                v-model="email"
                disabled
                expanded
                :icon-right="onlyCc ? 'times' : ''"
                icon-pack="fas"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <b-field>
              <p class="control">
                <span class="button is-static">Cc</span>
              </p>
              <b-taginput
                v-model="cc"
                ellipsis
                icon="label"
                :placeholder="i18n.addAEmail[iso]"
                aria-close-label="Delete this tag"
                expanded
                maxtags="5"
                close-type="is-danger"
              >
              </b-taginput>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <b-field :type="onlyCc ? 'is-danger' : ''">
              <p class="control">
                <span class="button is-static">Bcc</span>
              </p>
              <b-taginput
                v-model="bcc"
                ellipsis
                icon="label"
                :placeholder="i18n.addAEmail[iso]"
                aria-close-label="Delete this tag"
                expanded
                maxtags="5"
                close-type="is-danger"
                :disabled="onlyCc"
                :icon-right="onlyCc ? 'times' : ''"
                icon-pack="fas"
              >
              </b-taginput>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <b-field>
              <b-switch
                :rounded="false"
                :outlined="false"
                type="is-success"
                :left-label="true"
                passive-type="is-danger"
                v-model="onlyCc"
                >{{ i18n.onlySendToCopies[iso] }}:
              </b-switch>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a
        @click="$emit('close')"
        class="card-footer-item button is-danger is-small"
        style="height: auto"
        ><b>{{ i18n.cancel[iso] }}</b></a
      >
      <a
        @click="sendConfirmationEmail"
        :class="`card-footer-item button is-success is-small ${
          loading ? 'is-loading' : ''
        }`"
        style="height: auto"
        ><b>{{ i18n.send[iso] }}</b></a
      >
      <!-- <a href="#" class="card-footer-item">Delete</a> -->
    </footer>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import i18n from "../utils/i18n";

export default {
  name: "VacancyBackofficeModalSendConfirmation",
  props: ["numberRef", "email"],
  data() {
    return {
      isOpen: true,
      loading: false,
      cc: [],
      bcc: [],
      onlyCc: false,

      //
      tabXml: "req",
      lastTabXml: "req",

      //i18n
      i18n,
      iso: null,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
  mounted() {},

  methods: {
    sendConfirmationEmail() {
      this.loading = true;
      axios({
        method: "post",
        url: `${API_URL}/week/send-email/confirm-hold`,
        data: {
          numberRef: this.numberRef,
          bcc: this.bcc,
          cc: this.cc,
          onlyCc: this.onlyCc,
        },
      })
        .then(({ data }) => {
          console.log(data);
          this.loading = false;
          this.$buefy.toast.open({
            message: "Correo de confirmacion reenviado con exito",
            type: "is-success",
          });
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$buefy.toast.open({
            message: "Error al reenviar confirmacion",
            type: "is-danger",
          });
        });
    },
  },
};
</script>

<template>
  <div class="my-title">
    <h1><i v-if="icon" :class="`fas ${icon}`"></i> {{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "HeaderPage",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.my-title {
  margin: 3rem 0 1.5rem 0;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid #e8e8e8;
}

.my-title > h1 {
  font-size: 28px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: 700;
  gap: 1rem;
}
</style>

<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.users[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <div class="columns is-multiline">
      <div class="column is-2">
        <b-button
          icon-left="user-plus"
          icon-pack="fas"
          type="is-info"
          expanded
          @click="openModalCreateUserSystem"
          >{{ i18n.createUser[iso] }}</b-button
        >
      </div>
      <!-- <div class="column is-2">
        <b-button
          icon-left="tags"
          icon-pack="fas"
          type="is-primary"
          expanded
          @click="openModalCreateRoleSystem"
          >{{ i18n.createRole[iso] }}</b-button
        >
      </div> -->
    </div>
    <!-- <TableList
      :data="systemUsers"
      :columns="columns"
      :loading="loadingTableList"
      :actions="actions"
    /> -->
    <b-table
      :data="systemUsers"
      :paginated="isPaginated"
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :bordered="isBordered"
      :striped="true"
      :narrowed="isNarrowed"
      :hoverable="true"
      :loading="loadingTableList"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
      :detailed="false"
    >
      <template #detail="props">
        <b-table
          :data="getMembersStatic(props.row)"
          :bordered="true"
          :striped="false"
          :narrowed="isNarrowed"
          :hoverable="true"
          :focusable="isFocusable"
        >
          <b-table-column
            field="Nombre"
            :label="i18n.name[iso]"
            width="250"
            centered
            v-slot="propss"
          >
            <div
              v-html="
                `${formatTextBySearch(propss.row.Nombre, 'Nombre') || 'n/a'} ${
                  formatTextBySearch(
                    propss.row.ApellidoPaterno,
                    'ApellidoPaterno'
                  ) || 'n/a'
                }`
              "
            ></div>
            <!-- {{ propss.row.FullNombre }} -->
          </b-table-column>
          <b-table-column
            field="Telefono"
            :label="i18n.phone[iso]"
            width="250"
            centered
            v-slot="propss"
          >
            {{ propss.row.Telefono }}
          </b-table-column>
          <b-table-column
            field="Email"
            label="Email"
            width="250"
            centered
            v-slot="propss"
          >
            <div
              v-html="formatTextBySearch(propss.row.Email, 'Email') || 'n/a'"
            ></div>
            <!-- {{ propss.row.Email }} -->
          </b-table-column>
          <b-table-column
            field="Language"
            :label="i18n.language[iso]"
            width="250"
            centered
            v-slot="propss"
          >
            {{ propss.row.Language }}
          </b-table-column>
          <b-table-column
            field="TipoSocioId"
            :label="i18n.memberType[iso]"
            width="250"
            centered
            v-slot="propss"
          >
            <b-tag v-if="propss.row.TipoSocioId === 'T'" type="is-success">{{
              i18n.owner[iso]
            }}</b-tag>
            <b-tag v-else type="is-warning">{{ i18n.coOwner[iso] }}</b-tag>
            <!-- {{ propss.row.TipoSocioId }} -->
          </b-table-column>

          <!-- <b-table-column label="Actions" v-slot="props" centered width="170">
              <b-tooltip type="is-success is-light">
                <b-button
                  icon-left="eye"
                  icon-pack="fas"
                  size="is-small"
                  type="is-success is-light"
                  v-if="props.row.TipoSocioId === 'T'"
                  @click="
                    goPath(`/membership-profile?id=${props.row._id}&view=owner`)
                  "
                  style="margin-right: 10px"
                ></b-button>
                <template v-slot:content>
                  <b>{{ i18n.viewProfile[iso] }}</b></template
                >
              </b-tooltip>
              <b-tooltip type="is-info is-light">
                <b-button
                  icon-left="sync"
                  icon-pack="fas"
                  size="is-small"
                  type="is-info is-light"
                  v-if="props.row.TipoSocioId === 'T'"
                  @click="restorePassword"
                ></b-button>
                <template v-slot:content>
                  <b>{{ i18n.resetPassword[iso] }}</b></template
                >
              </b-tooltip>
            </b-table-column> -->
        </b-table>
        <b-table
          :data="getBeneficiariesStatic(props.row)"
          :bordered="true"
          :striped="false"
          :narrowed="isNarrowed"
          :hoverable="true"
          :focusable="isFocusable"
          v-if="getBeneficiariesStatic(props.row).length > 0"
        >
          <b-table-column
            field="Fullname"
            :label="i18n.beneficiaries[iso]"
            width="250"
            v-slot="propsss"
          >
            <div v-html="propsss.row"></div>
            <!-- {{ propsss.row }} -->
          </b-table-column>
        </b-table>
      </template>

      <b-table-column
        field="Usuario"
        :label="i18n.user[iso]"
        width="150"
        centered
        v-slot="props"
      >
        {{ props.row.Usuario || "" }}
      </b-table-column>

      <b-table-column
        field="Usuario"
        :label="i18n.fullname[iso]"
        width="150"
        centered
        v-slot="props"
      >
        {{ props.row.Nombre || "" }} {{ props.row.Apellido || "" }}
      </b-table-column>

      <b-table-column
        field="Usuario"
        :label="i18n.email[iso]"
        width="250"
        centered
        v-slot="props"
      >
        {{ props.row.Email || "" }}
      </b-table-column>

      <b-table-column
        field="TipoSocioId"
        :label="i18n.role[iso]"
        width="150"
        centered
        v-slot="props"
      >
        <b-tag type="is-info">{{
          props.row.Rol && iso === "es"
            ? props.row.Rol.Nombre
            : props.row.Rol && iso === "en"
            ? props.row.Rol.NombreEn
            : ""
        }}</b-tag>
      </b-table-column>

      <b-table-column
        field="TipoSocioId"
        :label="i18n.status[iso]"
        width="150"
        centered
        v-slot="props"
      >
        <b-tag v-if="props.row.Activo" type="is-success">{{
          i18n.active[iso]
        }}</b-tag>
        <b-tag v-else type="is-danger">{{ i18n.inactive[iso] }}</b-tag>
      </b-table-column>

      <b-table-column
        field="Usuario"
        :label="i18n.createdBy[iso]"
        width="150"
        centered
        v-slot="props"
      >
        {{ props.row.CreadoPor || "" }}
      </b-table-column>

      <!-- <b-table-column
        label="Actions"
        v-slot="props"
        centered
        width="170"
        v-if="$checkTotalPermissionsAvailable(['show-profile-members'])"
      >
        <b-tooltip
          type="is-success is-light"
          v-if="$checkPermission('show-profile-members')"
        >
          <b-button
            icon-left="eye"
            icon-pack="fas"
            size="is-small"
            type="is-success is-light"
            @click="goPath(`/membership-profile?id=${props.row._id}`)"
            style="margin-right: 10px"
          ></b-button>
          <template v-slot:content>
            <b>{{ i18n.viewContract[iso] }}</b></template
          >
        </b-tooltip>
      </b-table-column> -->
      <template #footer>
        <div class="columns">
          <div class="column is-11"></div>

          <div class="column is-1">
            <b-field :label="i18n.quantity[iso]">
              <b-select v-model="perPage" @input="savePerPage" expanded>
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <!-- <div class="has-text-right">Footer</div> -->
      </template>
    </b-table>

    <!-- Modals -->
    <b-modal
      v-model="ModalCreateUser"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <template #default="props">
        <modal-create-user-system
          :roles="roles"
          :languages="catalogues.CatIdiomas"
          @refreshUsers="listUsers"
          @refreshRoles="listRoles"
          @close="props.close"
        ></modal-create-user-system>
      </template>
    </b-modal>

    <b-modal
      v-model="ModalCreateRole"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <template #default="props">
        <modal-create-role-system
          @close="props.close"
        ></modal-create-role-system>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";
import TableList from "../../components/TableList.vue";
import ModalCreateUserSystem from "./ModalCreateUserSystem.vue";
import ModalCreateRoleSystem from "./ModalCreateRoleSystem.vue";

const listCatalogues = ["CatIdiomas"];

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,

      //Loading
      loadingTableList: false,
      loadingButton: false,

      //Data
      systemUsers: [],
      total: 0,
      roles: [],
      listCatalogues,
      catalogues: {},

      //Modals
      ModalCreateUser: false,
      ModalCreateRole: false,

      //Columns
      columns: [
        {
          field: "Nombre",
          label: "Nombre",
        },
        {
          field: "Apellido",
          label: "Apellido",
        },
        {
          field: "Email",
          label: "Email",
        },
        {
          field: "Rol",
          label: "Rol",
        },
        {
          field: "Activo",
          label: "Activo",
        },
      ],

      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",

      actions: {
        label: "Acciones",
        items: [
          // {
          //   icon: "copy",
          //   color: "is-info",
          //   exec: (item) => {
          //     if (item.Dominio) {
          //       navigator.clipboard.writeText(`${item.Dominio}/${item.Id}`);
          //       this.$buefy.toast.open({
          //         message: this.i18n.linkCopy__msg1[this.iso],
          //         type: "is-info",
          //       });
          //     }
          //   },
          // },
          // {
          //   icon: "paper-plane",
          //   color: "is-success",
          //   exec: (item) => this.sendEmailPaymentLink(item.Id),
          // },
        ],
      },
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //Permissions
    this.permissions = localStorage.getItem("role");

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //
    await this.listUsers();
    await this.listRoles();
    await this.getCatalogues();
  },
  components: {
    TableList,
    ModalCreateUserSystem,
    ModalCreateRoleSystem,
  },
  methods: {
    async listUsers() {
      try {
        this.loadingTableList = true;
        let query = `?limit=${this.perPage}&page=${this.currentPage}`;
        const { data } = await axios.post(
          `${API_URL}/system/list-users${query}`,
          {}
        );
        this.systemUsers = data?.users || [];
        this.total = data?.paginate?.total || 0;
        this.loadingTableList = false;
      } catch (err) {
        this.loadingTableList = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar usuarios",
          type: "is-danger",
        });
      }
    },
    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
      this.listUsers();
    },
    onPageChange(page) {
      this.currentPage = page;
      // console.log("checkAdvancedSearch()", checkAdvancedSearch());
      // if (this.searchMembers && this.searchMembers !== "") {
      //   this.listAllMembers({
      //     rgx: this.searchMembers,
      //   });
      // } else if (this.checkAdvancedSearch(false)) {
      //   this.listAllMembers(this.advancedFilters);
      // } else {
      //   this.listUsers();
      // }
    },
    openModalCreateUserSystem() {
      this.ModalCreateUser = true;
    },
    openModalCreateRoleSystem() {
      this.ModalCreateRole = true;
    },
    async listRoles() {
      try {
        // this.loadingTableList = true;
        const { data } = await axios.post(`${API_URL}/system/list-roles`, {});
        this.roles = data?.roles || [];
        // this.total = data?.paginate?.total || 0;
        // this.loadingTableList = false;
      } catch (err) {
        // this.loadingTableList = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar roles",
          type: "is-danger",
        });
      }
    },
    async getCatalogues() {
      try {
        // this.loading = true;

        const promises = [];

        for (let cat of listCatalogues) {
          promises.push(
            axios
              .get(`${API_URL}/catalogue/${cat}`)
              .then(({ data }) => {
                this.catalogues[cat] = data;
              })
              .catch((err) => {
                console.log(err);
                this.$buefy.toast.open({
                  message: "Error al consultar datos de catalogos",
                  type: "is-danger",
                });
              })
          );
          // const { data } = await axios.get(`${API_URL}/catalogue/${cat}`);
          // this.catalogues[cat] = data;
        }

        await Promise.all(promises);

        // console.log("catalogues", this.catalogues);
        // this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de catalogos",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <b-table
      :data="data"
      :loading="loading"
      :bordered="isBordered"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
      :class="size"
    >
      <b-table-column
        v-slot="props"
        v-if="checkable"
        class="checkbox"
        width="15"
      >
        <b-checkbox
          :native-value="
            selectorField !== 'object'
              ? getNestedValue(props.row, selectorField)
              : props.row
          "
          v-model="localSelectedRows"
        ></b-checkbox>
      </b-table-column>
      <b-table-column
        v-for="(item, idx) in columns"
        :key="idx"
        :label="item.label"
        width="250"
        centered
        v-slot="props"
      >
        <template v-if="item.field.includes('.')">
          <span :class="getRenewalStatusClass(props.row, item.field)">{{
            getNestedValue(props.row, item.field)
              ? formatValue(getNestedValue(props.row, item.field), item.type)
              : "-"
          }}</span>
        </template>
        <template v-else>
          <span
            :class="[
              item.field.includes('Estado')
                ? `label-status ${getNestedValue(props.row, item.field)}`
                : item.field.includes('Fecha')
                ? item.field
                : item.field.split('.')[1],
              getRenewalStatusClass(props.row, item.field),
              getActiveField(props.row, item.field, item.type),
            ]"
            >{{
              props.row[item.field]
                ? formatValue(props.row[item.field], item.type, props.row)
                : "-"
            }}</span
          >
        </template>
      </b-table-column>
      <b-table-column
        v-if="checkActions()"
        :label="actions.label"
        width="250"
        centered
        v-slot="props"
      >
        <b-button
          v-for="(itemAction, idx) in actions.items"
          :key="idx"
          :icon-left="itemAction.icon"
          icon-pack="fas"
          size="is-small"
          :type="itemAction.color"
          style="margin-right: 0.2rem"
          @click="itemAction.exec(props.row)"
        ></b-button>
      </b-table-column>
    </b-table>
    <div class="my-footer">
      <b-field :label="i18n.quantity[iso]" v-if="perPage">
        <b-select v-model="localLimitPage">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="20">20</option>
          <option :value="50">50</option>
        </b-select>
      </b-field>
      <div class="paginator" v-if="paginate">
        <div class="paginator-info">
          {{ currentPage }} - {{ paginate.totalPages }} &nbsp;&nbsp;&nbsp;
          <b>Total:</b>{{ paginate.total }}
        </div>
        <b-button
          type="is-primary"
          :disabled="localCurrentPage == 1 || loading"
          @click="prevPage()"
          ><i class="fas fa-chevron-left"></i
        ></b-button>
        <input type="number" v-model="localCurrentPage" />
        <b-button
          type="is-primary"
          :disabled="paginate.totalPages == localCurrentPage || loading"
          @click="nextPage()"
        >
          <i class="fas fa-chevron-right"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import i18n from "@/utils/i18n";

export default {
  props: {
    data: Array,
    columns: Array,
    loading: Boolean,
    checkable: Boolean,
    type: String,
    selectedRows: Array,
    perPage: Number,
    paginate: Object,
    currentPage: Number,
    loading: Boolean,
    size: String,
    actions: {
      type: Object,
      default: () => ({ items: [] }),
    },
    buttons: {
      type: Object,
      default: () => ({ items: [] }),
    },
    detailOpen: Boolean,
    selectorField: {
      type: String,
      default: "ContratoId.LoginID",
    },
  },
  data() {
    return {
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      i18n,
      iso: localStorage.getItem("iso"),
      localSelectedRows: this.selectedRows || [],
      localLimitPage: this.perPage,
      localCurrentPage: this.currentPage,
    };
  },
  watch: {
    localSelectedRows: {
      handler(newVal) {
        this.$emit("returnSelecteds", { type: this.type, data: newVal });
      },
      deep: true,
    },
    localLimitPage: {
      handler(newVal) {
        this.$emit("returnPage", newVal);
      },
      deep: true,
    },
    localCurrentPage: {
      handler(newVal) {
        if (
          newVal < 1 ||
          newVal > this.paginate.totalPages ||
          isNaN(newVal) ||
          !newVal
        ) {
          return;
        }
        this.$emit("returnCurrentPage", Number(newVal));
      },
      deep: true,
    },
  },
  created() {
    this.localSelectedRows = this.selectedRows;
  },
  computed: {
    startRecord() {
      return (this.localCurrentPage - 1) * this.paginate.totalNow + 1;
    },
    endRecord() {
      return Math.min(
        this.localCurrentPage * this.paginate.totalNow,
        this.paginate.total
      );
    },
  },
  methods: {
    getRenewalStatusClass(row, field) {
      const fechaRenovacion = this.getNestedValue(
        row,
        "ContratoId.FechaRenovacion"
      );
      if (!field.includes("FechaRenovacion")) return "";
      return fechaRenovacion < moment().valueOf() ? "vencido" : "activo";
    },
    getActiveField(row, field, type) {
      if (type == "boolean") {
        return this.getNestedValue(row, field) === true ? "activo" : "inactivo";
      }
    },
    getNestedValue(obj, path) {
      try {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
      } catch {
        return "N/A";
      }
    },
    formatValue(value, type, item) {
      if (type === "amount" && value) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value);
      }
      if (type === "date") {
        return moment(value).format("MM/DD/YYYY");
      }
      if (type === "boolean") {
        if (value === true) return "Activo";
        if (value === false) return "Inactivo";
      }
      if (type === "formated-date") {
        return moment(value).format("MM/DD/YYYY");
      }
      if (type === "discount") {
        if (item.type == "percentage") return `${value}%`;
        if (item.type == "fixed") {
          return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(value);
        }
      }
      return value;
    },
    checkActions() {
      return (
        this.actions && this.actions.items && this.actions.items.length > 0
      );
    },
    prevPage() {
      if (this.localCurrentPage > 1) {
        this.localCurrentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.paginate.totalPages) {
        this.localCurrentPage++;
      }
    },
    soloNumeros(event) {
      const value = event.target.value;
      const soloNumeros = value.replace(/\D/g, "");
      this.numero = soloNumeros;
      event.target.value = soloNumeros;
    },
  },
};
</script>

<style scoped>
.paid {
  background-color: #cffcb4;
  color: #417721;
  border: 1px solid rgba(65, 119, 33, 0.2);
}
.pending {
  background-color: #fef9c3;
  color: #ca8a04;
  border: 1px solid rgba(202, 139, 4, 0.2);
}

.open {
  background-color: #ffedd5;
  color: #ea580c;
  border: 1px solid rgba(234, 90, 12, 0.2);
}
.canceled {
  background-color: #ffd5d5;
  color: #ea0c0c;
  border: 1px solid rgba(234, 12, 12, 0.2);
}
.expired {
  background-color: #ffd5d5;
  color: #ea0c0c;
  border: 1px solid rgba(234, 12, 12, 0.2);
}
.canceled {
  background-color: #ffd5d5;
  color: #ea0c0c;
  border: 1px solid rgba(234, 12, 12, 0.2);
}

.sent {
  background-color: #dbeafe;
  color: #2563eb;
  border: 1px solid rgba(37, 100, 235, 0.2);
}

.my-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dbdbdb;
}

.label-status {
  font-size: 12px;
  padding: 2px 0.5rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.FechaCreacion {
  background-color: #cffcb4;
  color: #417721;
  border: 1px solid rgba(65, 119, 33, 0.2);
  font-size: 12px;
  padding: 2px 0.5rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.ExpiredDate {
  background-color: #fcb4b4;
  color: #772121;
  border: 1px solid rgba(119, 33, 33, 0.2);
  font-size: 12px;
  padding: 2px 0.5rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.button-container {
  display: flex;
  justify-content: flex-end !important;
  margin-top: 1rem;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.my-footer {
  padding: 0 1rem;
}

.paginator {
  display: flex;
  gap: 5px;
}

.paginator > input {
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  text-align: center;
}

.paginator > div {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 1rem;
  border-radius: 5px;
  font-size: 12px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.checkbox {
  width: fit-content !important;
}
.vencido {
  background-color: #fcb4b4;
  color: #772121;
  border: 1px solid rgba(119, 33, 33, 0.2);
  font-size: 12px;
  padding: 2px 0.5rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.activo {
  background-color: #cffcb4;
  color: #417721;
  border: 1px solid rgba(65, 119, 33, 0.2);
  font-size: 12px;
  padding: 2px 0.5rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.inactivo {
  background-color: #fcb4b4;
  color: #772121;
  border: 1px solid rgba(119, 33, 33, 0.2);
  font-size: 12px;
  padding: 2px 0.5rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
}

.small {
  font-size: 14px;
}

.xsmall {
  font-size: 12px;
}
</style>

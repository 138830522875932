<template>
  <section>
    <div class="myassign-title">{{ getTranslation("reassignment") }}</div>
    <div class="my-buttons">
      <b-button
        icon-left="check-square"
        icon-pack="fas"
        type="is-success"
        @click="checkAll('mark')"
      >
        {{ getTranslation("selectAll") }}
      </b-button>
      <b-button
        icon-left="check-square"
        icon-pack="fas"
        type="is-danger"
        @click="checkAll('unmark')"
        v-if="checked.length > 0"
      >
        {{ getTranslation("unselectAll") }}
      </b-button>
      <b-button
        icon-left="save"
        icon-pack="fas"
        type="is-info"
        @click="sendReasignmets()"
        v-if="checked.length > 0"
      >
        {{ getTranslation("reasign") }}
      </b-button>
      <b-button
        icon-left="trash"
        icon-pack="fas"
        type="is-danger"
        @click="discardReasign()"
      >
        {{ getTranslation("discard") }}
      </b-button>
    </div>
    <b-table
      :data="assignments"
      v-if="assignments.length > 0"
      striped
      hoverable
    >
      <b-table-column class="checkbox">
        <template v-slot="props">
          <b-checkbox :native-value="props.row" v-model="checked"></b-checkbox>
        </template>
      </b-table-column>
      <b-table-column field="agent.name" label="LoginID" custom-key="agent">
        <template slot-scope="props">
          {{ `${props.row.from.ContratoId.LoginID}` }}
        </template>
      </b-table-column>
      <b-table-column
        field="agent.name"
        :label="getTranslation('nroContract')"
        custom-key="agent"
      >
        <template slot-scope="props">
          {{ `${props.row.from.ContratoId.Numero}` }}
        </template>
      </b-table-column>
      <b-table-column
        field="agent.name"
        :label="getTranslation('assignedAt')"
        custom-key="agent"
      >
        <template slot-scope="props">
          {{ formatValue(props.row.from.assignedAt, "date") }}
        </template>
      </b-table-column>

      <b-table-column
        field="agent.name"
        :label="getTranslation('reassignment')"
        custom-key="agent"
      >
        <template slot-scope="props">
          {{
            `${props.row.from.userId.Nombre} ${props.row.from.userId.Apellido} `
          }}<i class="fas fa-arrow-right"></i>
          {{ ` ${props.row.to.Nombre} ${props.row.to.Apellido}` }}
        </template>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import i18n from "@/utils/i18n";
import axios from "axios";
import moment from "moment";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  props: {
    reAssignment: {
      type: Array,
      required: true,
    },
  },
  name: "ReAssignment",
  data() {
    return {
      iso: localStorage.getItem("iso") || "en",
      i18n,
      assignments: [],
      checked: [],
    };
  },
  created() {
    this.$bus.on("change-iso", this.updateIso);
    this.checkSameAgent();
  },
  beforeDestroy() {
    this.$bus.off("change-iso", this.updateIso);
  },
  methods: {
    updateIso() {
      this.iso = localStorage.getItem("iso");
      this.$forceUpdate();
    },
    checkSameAgent() {
      this.assignments = this.reAssignment.filter(
        (assign) => assign.from.userId._id !== assign.to._id
      );
    },
    checkAll(value) {
      if (value == "mark") {
        this.checked = this.assignments;
      } else if (value == "unmark") {
        this.checked = [];
      }
    },
    getTranslation(key) {
      return this.i18n[key]?.[this.iso] || key;
    },
    sendReasignmets() {
      if (this.checked.length === 0) {
        this.$buefy.toast.open({
          message: `No ha seleccionado re-asignaciones, en caso de no requerir presione "descartar"`,
          type: "is-danger",
        });
        return;
      }
      axios
        .post(
          `${API_URL}/assignments/reasign?type=renewal&reasign=true`,
          this.checked
        )
        .then((res) => {
          this.$emit("reasingCompleted", res.data);
          this.checked = [];
        })
        .catch((err) => {
          console.error(err);
        });
    },
    discardReasign() {
      if (confirm("Desea salir del panel de re-asignación?") == true) {
        this.$emit("discardReasign");
        this.checked = [];
      }
    },
    formatValue(value, type) {
      if (type === "amount" && value) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value);
      }
      if (type === "date") {
        return moment(value).format("MM/DD/YYYY");
      }
      return value;
    },
  },
};
</script>

<style scoped>
.my-buttons {
  border: 1px solid #dbdbdb;
  border-left: 0px;
  border-right: 0px;
  padding: 0.5rem;
  display: flex;
  justify-content: end;
  gap: 0.5rem;
}

.myassign-title {
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: 600;
}
</style>

<template>
  <div class="progress-steps">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="[
        'step',
        { active: index < currentStep - 1, current: index === currentStep - 1 },
      ]"
    >
      <div class="step-circle">
        <span class="step-title">{{ step }}</span>
      </div>
      <div class="step-line" v-if="index < steps.length - 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.progress-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.step {
  display: flex;
  align-items: center;
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: rgb(197, 197, 197);
  position: relative;
  font-size: 10px;
}

.step-circle > span {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
}

.step-circle::before {
  content: "";
  display: none;
}

.step.current .step-circle::before {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #7755d1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.step-line {
  width: 100px;
  height: 4px;
  background-color: lightgray;
}

.step.active .step-circle,
.step.current .step-circle {
  background-color: #7755d1;
  color: #7755d1;
}

.step.active .step-line,
.step.current .step-line {
  background-color: #7755d1;
}
</style>

<template>
  <div class="card" style="width: 800px; height: auto">
    <header class="card-header">
      <p class="card-header-title" style="font-size: large">Historial - RCI</p>
      <button
        class="card-header-icon"
        aria-label="more options"
        @click="$emit('close')"
      >
        <span class="icon">
          <i class="fas fa-times" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="content">
        <section>
          <section>
            <b-collapse
              v-for="(log, idx) in logs"
              :key="idx"
              class="card"
              animation="slide"
              :aria-id="`${log.type}${idx}`"
              v-model="log.open"
            >
              <template #trigger="props">
                <div
                  class="card-header"
                  role="button"
                  :aria-controls="`${log.type}${idx}`"
                  :aria-expanded="log.open"
                >
                  <p class="card-header-title" @click="actionOpenLog(idx)">
                    <b-taglist attached>
                      <b-tag
                        size="is-medium"
                        :type="
                          log.type === 'hold'
                            ? 'is-warning'
                            : log.type === 'confirmHold'
                            ? 'is-success'
                            : log.type === 'addTraveler'
                            ? 'is-info'
                            : 'is-danger'
                        "
                        >{{ log.type }}</b-tag
                      >
                      <b-tag type="is-dark" size="is-medium">{{
                        dateToString(log.createdAtMs)
                      }}</b-tag>
                    </b-taglist>
                    <!-- <b-button
                      icon-left="paper-plane"
                      icon-pack="fas"
                      size="is-small"
                      type="is-success"
                    ></b-button> -->
                  </p>
                  <a class="card-header-icon">
                    <b-icon
                      :icon="log.open ? 'sort-down' : 'sort-up'"
                      pack="fas"
                    >
                    </b-icon>
                  </a>
                </div>
              </template>

              <div class="card-content">
                <b-tabs position="is-centered" size="is-small" type="is-boxed">
                  <b-tab-item label="Solicitud">
                    <!-- style="width: 730px; height: 380px" -->
                    <b-button
                      icon-left="copy"
                      icon-pack="fas"
                      size="is-small"
                      type="is-info"
                      expanded
                      @click="copyXml(log.body)"
                      >{{ i18n.copyXml[iso] }}</b-button
                    >
                    <textarea cols="95" rows="10" disabled>
 
                    {{ parseXml(log.body) }}
                  </textarea
                    >
                  </b-tab-item>

                  <b-tab-item label="Respuesta">
                    <b-button
                      icon-left="copy"
                      icon-pack="fas"
                      size="is-small"
                      type="is-info"
                      expanded
                      @click="copyXml(log.response)"
                      >{{ i18n.copyXml[iso] }}</b-button
                    >
                    <textarea cols="95" rows="10" disabled>
                    {{ parseXml(log.response) }}
                  </textarea
                    >
                  </b-tab-item>
                  <!-- <b-tab-item
                    icon="copy"
                    icon-pack="fas"
                    value="copy"
                    :label="`${tabXml}-${lastTabXml}`"
                  ></b-tab-item> -->
                </b-tabs>
              </div>
            </b-collapse>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
const XmlBeautify = require("xml-beautify");
const { DOMParser } = require("xmldom");
import i18n from "../utils/i18n";

export default {
  name: "VacancyBackofficeModalLogs",
  props: ["numberRef"],
  data() {
    return {
      isOpen: true,
      logs: [],

      //
      tabXml: "req",
      lastTabXml: "req",

      //i18n
      i18n,
      iso: null,
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },
  mounted() {
    this.listLogs();
  },

  methods: {
    listLogs() {
      axios({
        method: "get",
        url: `${API_URL}/logs?numberRef=${this.numberRef}`,
      })
        .then(({ data }) => {
          console.log(data);
          this.logs = data.logs.map((l) => {
            return {
              ...l,
              open: false,
            };
          });
        })
        .catch((err) => {
          console.log(err);
          this.$buefy.toast.open({
            message: "Error al consultar historial de " + this.numberRef,
            type: "is-danger",
          });
        });
    },
    dateToString(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    parseXml(xml) {
      if (
        xml.toString().indexOf("requestEnvelope") >= 0 ||
        xml.toString().indexOf("responseEnvelope") >= 0
      ) {
        console.log(
          "xml",
          xml,
          xml.toString().indexOf("requestEnvelope"),
          xml.toString().indexOf("responseEnvelope")
        );
        const beautifiedXmlText = new XmlBeautify({
          parser: DOMParser,
        }).beautify(xml);
        // console.log(beautifiedXmlText);
        return beautifiedXmlText;
      } else return xml;
    },
    actionOpenLog(idx) {
      for (let i in this.logs) if (i !== idx) this.logs[i].open = false;
    },
    copyXml(xml) {
      navigator.clipboard.writeText(xml);
      this.$buefy.toast.open({
        message: "XML copiado con exito",
        type: "is-info",
      });
    },
  },
};
</script>

<template>
  <section>
    <b-modal
      v-model="localIsActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Crear código de descuento"
      close-button-aria-label="Cerrar"
      aria-modal
    >
      <template #default="{ close }">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Crear código de descuento</p>
            <button
              type="button"
              class="delete"
              aria-label="close"
              @click="closeModal"
            ></button>
          </header>
          <section class="modal-card-body">
            <b-field label="Código" label-position="on-border" class="spaced">
              <b-input v-model="code.code"></b-input>
            </b-field>

            <b-field
              label="Fecha de expiración"
              label-position="on-border"
              class="spaced"
            >
              <b-datepicker
                v-model="selected"
                icon="calendar-today"
                :icon-right="selected ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDate"
                trap-focus
              >
              </b-datepicker>
            </b-field>

            <b-field label="Tipo" label-position="on-border" class="spaced">
              <b-select v-model="code.type" expanded>
                <option value="percentage">Porcentaje</option>
                <option value="fixed">Fijo</option>
                <option value="reset">Restablecer</option>
              </b-select>
            </b-field>

            <b-field
              :label="`Descuento en ${
                code.type === 'fixed' ? 'dolares' : 'porcentaje'
              }`"
              v-if="code.type !== 'reset'"
              label-position="on-border"
              class="spaced"
            >
              <b-input v-model="code.discount" type="number"></b-input>
            </b-field>

            <b-field
              label="Descuento Máximo en dolares"
              label-position="on-border"
              v-if="code.type !== 'reset'"
            >
              <b-input v-model="code.maxDiscount" type="number"></b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button @click="closeModal">Cancelar</b-button>
            <b-button type="is-primary" @click="submitForm">Guardar</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import moment from "moment";
export default {
  name: "GenerateCodeModal",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    code: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localIsActive: this.isActive,
      selected: null,
    };
  },
  watch: {
    isActive(newValue) {
      this.localIsActive = newValue;
    },
    localIsActive(newValue) {
      this.$emit("update:isActive", newValue);
    },
    selected(newValue) {
      this.code.expirationDate = moment(newValue).valueOf();
    },
  },
  methods: {
    closeModal() {
      this.localIsActive = false;
    },
    submitForm() {
      this.$emit("submit", this.code);
      this.closeModal();
    },
    clearDate() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
.spaced {
  margin-bottom: 1.5rem !important;
}
</style>

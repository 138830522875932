<template>
  <div>
    <HeaderPage :title="i18n.paymentsLinks[iso]" icon="fa-link" />
    <div class="my-container">
      <FilterForm
        :advancedFilters="advancedFilters"
        :filterFields="advancedFiltersFields"
        @advancedSearch="advancedSearch"
        @clearFilters="clearFilters"
      ></FilterForm>
      <div class="my-hr"></div>
      <div class="my-buttons">
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              :label="i18n.createPaymentsLinks[iso]"
              type="is-info"
              class="create-paymentlink"
              :icon-right="active ? 'menu-up' : 'menu-down'"
              :loading="loadingButton"
            />
          </template>

          <b-dropdown-item
            aria-role="listitem"
            @click="setReferenceForCreateReservation"
          >
            <b>{{ i18n.reservation[iso] }}</b>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="renew"
            ><b>{{ i18n.renovations[iso] }}</b></b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div class="my-hr"></div>
      <TableList
        :data="paymentslinks"
        :columns="columns"
        :loading="loadingTableList"
        :actions="actions"
        :detailOpen="detailOpen"
        :paginate="paginate"
        :perPage="perPage"
        :currentPage="currentPage"
        size="small"
        @returnPage="changeLimitPage"
        @returnCurrentPage="changeCurrentPage"
      />
    </div>

    <!-- Modals -->
    <GenerateReservationModal
      :isActive.sync="isModalActive"
      @submit="createReservation"
      :reservation="reservation"
    />

    <b-modal
      v-model="ActiveModalCreatePaymentReservation"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <template #default="props">
        <ModalCreatePaymentLinkReservation
          @close="props.close"
          @refresh="listPaymentsLinks"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
const SHORT_URL = process.env.VUE_APP_SHORT_URL;
import i18n, { advancedSearch, paymentLink } from "../../utils/i18n";
import TableList from "../../components/TableList.vue";
import FilterForm from "../../components/FilterForm.vue";
import ModalCard from "../../components/ModalCard.vue";
import HeaderPage from "@/components/HeaderPage.vue";
import GenerateReservationModal from "@/views/Bookings/GenerateReservationModal.vue";

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      detailOpen: true,
      //Loading
      loadingTableList: false,
      loadingButton: false,
      // filters
      advancedFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        club: null,
        status: null,
        onlyHeadlines: false,
        contractStatus: null,
        EstadoPaymentLink: [],
      },
      advancedFiltersFields: [
        "Nombre",
        "ApellidoPaterno",
        "Email",
        "LoginID",
        "nroContract",
        "FechaRegistro",
        "FechaProcesable",
        "FechaRenovacion",
        "FechaCancelacion",
        "club",
        "status",
        "onlyHeadlines",
        "language",
        "contractStatus",
      ],
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },
      //Data
      paymentslinks: [],

      //Modals
      reservation: {
        userId: window.localStorage.getItem("userId"),
        Moneda: "usd",
        Habitaciones: 1,
      },
      isModalActive: false,
      ActiveModalCreatePaymentReservation: false,

      //i18n
      i18n,
      iso: null,

      //Columns
      columns: [],
      /* Paginator */
      paginate: {},

      //Permissions
      permissions: "",

      actions: {
        label: "Acciones",
        items: [
          {
            icon: "copy",
            color: "is-info",
            exec: (item) => {
              if (item.Id) {
                navigator.clipboard.writeText(`${SHORT_URL}/${item.Id}`);
                this.$buefy.toast.open({
                  message: this.i18n.linkCopy__msg1[this.iso],
                  type: "is-info",
                });
              }
            },
          },
          {
            icon: "paper-plane",
            color: "is-success",
            exec: (item) => this.sendEmailPaymentLink(item, item.Id),
          },
          {
            icon: "eye",
            color: "is-link",
            exec: (item) => this.goPath(item._id),
          },
          {
            icon: "trash",
            color: "is-danger",
            exec: (item) => this.cancelPaymentLink(item._id),
          },
        ],
      },
      headerActions: {
        label: "Acciones",
        items: [
          {
            icon: "copy",
            color: "is-info",
            exec: (item) => {},
          },
        ],
      },
    };
  },
  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
      this.updateColumns();
    });
    this.updateColumns();

    //PerPage
    // const perPageLocalStorage = localStorage.getItem("perPageReserves");
    // if (!perPageLocalStorage) this.savePerPage();
    // else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //
    this.listPaymentsLinks();
  },
  components: {
    TableList,
    FilterForm,
    HeaderPage,
    GenerateReservationModal,
  },
  methods: {
    async listPaymentsLinks(filters = {}) {
      try {
        this.loadingTableList = true;
        const { data } = await axios.post(
          `${API_URL}/payments/list?limit=${this.perPage}&page=${this.currentPage}`,
          filters
        );
        this.paymentslinks = data?.paymentLinks || [];
        this.paginate = data?.paginate;
        this.loadingTableList = false;
      } catch (err) {
        this.loadingTableList = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar enlaces de pago",
          type: "is-danger",
        });
      }
    },
    async sendEmailPaymentLink(item, id) {
      const notSend = ["paid", "canceled", "expired"];
      if (notSend.includes(item.Estado)) {
        this.$buefy.toast.open({
          message: "No se puede enviar enlace de pago por su estado",
          type: "is-danger",
        });
        return;
      }
      try {
        let query = `?type=${
          item.Tipo == "reservation" ? "reservation" : "renewal"
        }`;
        await axios.get(`${API_URL}/paymentlink/send-email/${id}${query}`);
        this.$buefy.toast.open({
          message: this.i18n.linkCopy__msg2[this.iso],
          type: "is-success",
        });
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al enviar enlace de pago",
          type: "is-danger",
        });
      }
    },

    changeLimitPage(limit) {
      this.perPage = limit;
      this.currentPage = 1;
      this.listPaymentsLinks(this.advancedFilters);
    },
    changeCurrentPage(page) {
      this.currentPage = page;
      this.listPaymentsLinks(this.advancedFilters);
    },
    openModalCreateLinkReservation() {
      // this.ActiveModalCreatePaymentReservation = true;
      this.$buefy.modal.open({
        parent: this,
        component: ModalCard,
        hasModalCard: true,
        trapFocus: true,
        props: {
          title: "Hola Jose",
        },
      });
    },
    setReferenceForCreateReservation() {
      /* this.$buefy.dialog.prompt({
        message: this.i18n.createPaymentsLinks__msg1[this.iso],
        confirmText: this.i18n.next[this.iso],
        inputAttrs: {
          maxlength: 10,
          required: true,
        },
        trapFocus: true,
        onConfirm: (reference) => {
          this.$buefy.dialog.prompt({
            message: this.i18n.createPaymentsLinks__msg2[this.iso],
            confirmText: this.i18n.next[this.iso],
            inputAttrs: {
              required: true,
            },
            trapFocus: true,
            onConfirm: (email) => {
              this.createPaymentLinkReservation(reference, email);
            },
          });
        },
      }); */
      this.isModalActive = true;
    },
    async clearFilters() {
      this.advancedFilters = {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        club: null,
        status: null,
        onlyHeadlines: false,
        language: null,
        contractStatus: null,
        EstadoPaymentLink: [],
      };
      console.log("Limpiando filtros");
      await this.listPaymentsLinks(this.advancedFilters);
    },
    async createPaymentLinkReservation(reference, email) {
      try {
        this.loadingButton = true;
        await axios.post(`${API_URL}/paymentlink/create-reservation`, {
          reference,
          CreadoPor: localStorage.getItem("user"),
          CreadoPorEmail: localStorage.getItem("userEmail"),
          email,
        });
        this.loadingButton = false;
        this.$buefy.toast.open({
          message: this.i18n.createPaymentsLinks__msgOk[this.iso],
          type: "is-success",
        });
        await this.listPaymentsLinks();
      } catch (err) {
        this.loadingButton = false;
        console.log(err);

        let message = err?.response?.data?.message;
        if (message) {
          this.$buefy.toast.open({
            message,
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: "Error al consultar enlaces de pago",
            type: "is-danger",
          });
        }
      }
    },

    renew() {
      this.$buefy.dialog.prompt({
        message: this.i18n.renew__message1[this.iso],
        confirmText: this.i18n.next[this.iso],
        inputAttrs: {
          type: "number",
          value: 1,
          min: 0,
          max: 99,
        },
        trapFocus: true,
        onConfirm: (years) => {
          this.$buefy.dialog.prompt({
            message: this.i18n.renew__message3[this.iso],
            confirmText: this.i18n.next[this.iso],
            inputAttrs: {
              type: "number",
              value: 1,
              min: -100,
              max: 99,
            },
            trapFocus: true,
            onConfirm: (contractId) => {
              this.sendRenew(years, contractId);
            },
          });
        },
      });
    },
    async sendRenew(qty, contractId) {
      try {
        this.loadingButton = true;
        await axios.post(`${API_URL}/renewals/create`, {
          contractId,
          qty,
          agentEmail: localStorage.getItem("userEmail" || null),
        });
        this.loadingButton = false;
        await this.listPaymentsLinks();
        this.$buefy.toast.open({
          message: "Renewal OK",
          type: "is-success",
        });
      } catch (err) {
        console.log(err);
        this.loadingButton = false;
        this.$buefy.toast.open({
          message: "Error al renovar socio",
          type: "is-danger",
        });
      }
    },
    updateColumns() {
      this.columns = [
        {
          field: "contract.LoginID",
          label: "LoginID",
          type: "text",
        },
        {
          field: "Tipo",
          label: this.i18n.type[this.iso],
          type: "text",
        },
        {
          field: "Monto",
          label: this.i18n.amount[this.iso],
          type: "amount",
        },
        {
          field: "Estado",
          label: this.i18n.status[this.iso],
          type: "text",
        },
        {
          field: "FechaCreacion",
          label: this.i18n.creationDate[this.iso],
          type: "date",
        },
        {
          field: "CreadoPor",
          label: this.i18n.createdBy[this.iso],
          type: "text",
        },
      ];
    },
    handleRowAction(action, row) {
      if (action === "copyId") {
        navigator.clipboard.writeText(row._id).then(() => {
          this.$buefy.toast.open({
            message: "ID copiado al portapapeles",
            type: "is-info",
          });
        });
      } else if (action === "sendEmail") {
        this.sendEmailPaymentLink(row._id);
      }
    },
    advancedSearch() {
      this.listPaymentsLinks(this.advancedFilters);
    },
    goPath(id) {
      this.$router.push({ path: "/payments-links-details", query: { id } });
    },
    cancelPaymentLink(id) {
      this.$buefy.dialog.confirm({
        message: "¿Estás seguro de cancelar el enlace de pago?",
        confirmText: "Sí",
        cancelText: "No",
        onConfirm: async () => {
          try {
            await axios.delete(`${API_URL}/payments/retrieve/${id}`);
            this.$buefy.toast.open({
              message: "Enlace de pago cancelado",
              type: "is-success",
            });
            await this.listPaymentsLinks();
          } catch (err) {
            console.log(err);
            this.$buefy.toast.open({
              message: "Error al cancelar enlace de pago",
              type: "is-danger",
            });
          }
        },
      });
    },
    createReservation() {
      const { Monto, ...restReservation } = this.reservation;
      axios
        .post(`${API_URL}/reservations/create`, {
          ...restReservation,
          Monto: parseFloat(Monto),
          paymentLink: true,
        })
        .then(() => {
          this.$buefy.toast.open({
            message: "Reservación creada exitosamente",
            type: "is-success",
          });
          this.isModalActive = false;
          this.getBookings();
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: "Error al crear la reservación",
            type: "is-danger",
          });
        });
    },
  },
};
</script>

<style>
.create-paymentlink {
  padding: 1rem 1rem !important;
}
</style>
